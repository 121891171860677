"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DropdownContent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DropdownContent(_ref) {
  var {
    setIsOpen,
    rooms,
    addRoom,
    removeRoom,
    updateRoom,
    onSubmit,
    adultsOptions,
    childrenOptions,
    labels,
    maxNumberOfRooms,
    dataRoomOccupancyLimitations,
    roomCodes,
    dataTestId,
    screenSize,
    showMultipleRooms,
    channel,
    className
  } = _ref;
  var roomOccupancies = dataRoomOccupancyLimitations.roomOccupancyLimitations.roomOccupancies;
  var isTestTargetRoomPickerRedesign = () => {
    if (typeof window !== 'undefined') {
      var horizontalRoomPickerCookie = (0, _utils.getCookie)(_api.SEARCH_ROOM_PICKER);
      if (horizontalRoomPickerCookie) {
        return true;
      } else {
        var _window;
        if (((_window = window) === null || _window === void 0 || (_window = _window.piConfig) === null || _window === void 0 ? void 0 : _window.roomPickerRedesign) === 'horizontalStackVariant' && channel !== _api.BOOKING_CHANNEL.CCUI) {
          (0, _utils.setCookie)(_api.SEARCH_ROOM_PICKER, 'horizontal-variant', _api.THIRTY_MINUTES);
          return true;
        }
        return false;
      }
    }
  };
  var isTestTargetRoomPickerRedesignEnabled = isTestTargetRoomPickerRedesign();
  var onButtonClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    onSubmit();
    setIsOpen && setIsOpen(false);
  };
  var {
    removeRoomButtonLabel,
    roomsWarningTitle,
    roomsWarningDescription,
    roomsWarningDescriptionCCUI,
    addMoreRoomsLabel,
    doneButtonLabel,
    adultsLabel,
    adultsMaxPerRoomLabel,
    childrenLabel,
    childrenAgeLabel,
    cotLimit,
    cotLabel,
    roomTypeLabel,
    roomLabel,
    single,
    double,
    accessible,
    twin,
    family
  } = labels;
  var roomTypeOccupancies = {
    single,
    double,
    accessible,
    twin,
    family
  };
  var {
    isLessThanXs,
    isLessThanSm,
    isLessThanMd
  } = screenSize;
  var rootPropsMenuList = (0, _react2.useMemo)(() => isLessThanXs ? menuListRootPropsLessThanXs : isLessThanSm ? menuListRootPropsLessThanSm : isLessThanMd ? menuListRootPropsLessThanMd : {}, [isLessThanXs, isLessThanSm, isLessThanMd]);
  return (0, _jsxRuntime.jsx)(_react.MenuList, {
    "data-testid": isTestTargetRoomPickerRedesignEnabled ? 'roomPickerMenu-variant' : 'roomPickerMenu',
    w: menuListWidth,
    rootProps: rootPropsMenuList,
    overflow: "hidden",
    zIndex: "1801",
    sx: isTestTargetRoomPickerRedesignEnabled ? horizontalVariantStyles() : '',
    children: rooms.map((room, index) => {
      var isLastIndex = rooms.length === index + 1;
      return (0, _jsxRuntime.jsx)(_react.MenuGroup, {
        children: (0, _jsxRuntime.jsx)(_react.Menu, {
          flip: false,
          children: (0, _jsxRuntime.jsxs)(_react.MenuItem, _objectSpread(_objectSpread({
            as: "div",
            flexDirection: "column"
          }, menuItemStyles), {}, {
            children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
              w: "full",
              justifyContent: "".concat(rooms.length > 1 ? 'space-between' : 'start'),
              className: "roompicker-room-heading",
              children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, roomLabelStyles), {}, {
                children: [roomLabel, " ", index + 1]
              })), rooms.length > 1 && (0, _jsxRuntime.jsx)(_react.Link, {
                color: "btnSecondaryEnabled",
                textDecoration: "underline",
                onClick: () => removeRoom(room.id),
                children: removeRoomButtonLabel
              })]
            }), (0, _jsxRuntime.jsxs)(_react.VStack, {
              w: "full",
              mt: "2rem",
              className: "roompicker-stack",
              children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
                w: "full",
                justify: "space-between",
                className: "roompicker-occupancy",
                children: [(0, _jsxRuntime.jsxs)(_react.VStack, {
                  spacing: 0,
                  children: [(0, _jsxRuntime.jsx)(_atoms.Dropdown, {
                    onChange: p => updateRoom(room.id, _constants.ADULTS_KEY, p === null || p === void 0 ? void 0 : p.id, labels),
                    dropdownStyles: {
                      menuButtonStyles: menuButtonStyles
                    },
                    label: adultsLabel,
                    icon: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                      svg: (0, _jsxRuntime.jsx)(_atoms.OneAdult, {})
                    }),
                    placeholder: String(room.adults),
                    options: adultsOptions,
                    matchWidth: true,
                    dataTestId: "".concat(dataTestId, "-adults")
                  }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, helperTextStyles), {}, {
                    children: adultsMaxPerRoomLabel
                  }))]
                }), (0, _jsxRuntime.jsxs)(_react.VStack, {
                  spacing: 0,
                  children: [(0, _jsxRuntime.jsx)(_atoms.Dropdown, {
                    onChange: p => updateRoom(room.id, _constants.CHILDREN_KEY, p === null || p === void 0 ? void 0 : p.id, labels),
                    dropdownStyles: {
                      menuButtonStyles: menuButtonStyles
                    },
                    placeholder: String(room.children),
                    label: childrenLabel,
                    options: childrenOptions,
                    matchWidth: true,
                    dataTestId: "".concat(dataTestId, "-children")
                  }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, helperTextStyles), {}, {
                    children: childrenAgeLabel
                  }))]
                })]
              }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, cotSwitcherStyles), {}, {
                className: "roompicker-cot-switcher",
                children: [(0, _jsxRuntime.jsx)(_atoms.Switcher, {
                  size: "lg",
                  isChecked: room.shouldIncludeCot,
                  onChange: status => updateRoom(room.id, _constants.SHOULD_INCLUDE_COT, status.isChecked, labels)
                }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, cotLabelStyles), {}, {
                  children: cotLabel
                })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, cotLimitStyles), {}, {
                  children: cotLimit
                }))]
              })), (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
                dataTestId: "".concat(dataTestId, "-roomTypeDropdown"),
                className: className,
                onChange: p => updateRoom(room.id, _constants.ROOM_TYPE_KEY, p === null || p === void 0 ? void 0 : p.id, labels),
                dropdownStyles: {
                  menuButtonStyles: {
                    w: '100%'
                  },
                  wrapperStyles: {
                    mt: '1.5rem'
                  }
                },
                placeholder: room.roomType,
                label: roomTypeLabel,
                matchWidth: true,
                options: (0, _utils.getRoomTypeOptions)(room, roomOccupancies, roomTypeOccupancies, roomCodes).flat()
              }), isLastIndex ? renderButton(addRoom, onButtonClick, rooms, roomsWarningTitle, roomsWarningDescription, roomsWarningDescriptionCCUI, addMoreRoomsLabel, doneButtonLabel, maxNumberOfRooms, showMultipleRooms, channel) : renderDivider()]
            })]
          }))
        })
      }, room.id);
    })
  });
}
function renderButton(addRoom, onButtonClick, rooms, roomsWarningTitle, roomsWarningDescription, roomsWarningDescriptionCCUI, addMoreRoomsLabel, doneButtonLabel, maxNumberOfRooms, showMultipleRooms, channel) {
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(rooms === null || rooms === void 0 ? void 0 : rooms.length) === maxNumberOfRooms ? (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "xl",
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        variant: "alert",
        status: "warning",
        title: roomsWarningTitle,
        description: (0, _jsxRuntime.jsx)(_atoms.DescriptionBox, {
          html: displayDescription(roomsWarningDescription, roomsWarningDescriptionCCUI, channel)
        }),
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      })
    }) : (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
      onClick: () => addRoom(),
      w: "full",
      pl: "md",
      alignItems: "center",
      "data-testid": "addMoreRooms"
    }, addAnotherRoomWrapperStyles), {}, {
      children: !showMultipleRooms && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Icon, _objectSpread(_objectSpread({}, addAnotherRoomButtonStyles), {}, {
          svg: (0, _jsxRuntime.jsx)(_atoms.Path, {
            color: "var(--chakra-colors-primary)"
          })
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, addAnotherRoomLabelStyles), {}, {
          "data-testid": "addMoreRoomsLabel",
          children: addMoreRoomsLabel
        }))]
      })
    })), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      w: "full",
      onClick: onButtonClick,
      size: "md",
      variant: "tertiary",
      "data-testid": "doneButton"
    }, doneButtonStyles), {}, {
      children: doneButtonLabel
    }))]
  });
}
function renderDivider() {
  return (0, _jsxRuntime.jsx)(_react.Box, {
    w: "full",
    mt: "2rem",
    className: "roompicker-divider",
    children: (0, _jsxRuntime.jsx)(_react.Divider, {
      orientation: "horizontal"
    })
  });
}
function displayDescription(roomsWarningDescription, roomsWarningDescriptionCCUI, channel) {
  if (channel && channel === _api.BOOKING_CHANNEL.CCUI) {
    return roomsWarningDescriptionCCUI;
  }
  return roomsWarningDescription;
}
var menuButtonStyles = {
  w: '8.5rem'
};
var menuListWidth = {
  mobile: '20rem',
  xs: '21.4375rem',
  sm: '20rem',
  md: 'var(--chakra-space-breakpoint-m)'
};
var menuListRootPropsLessThanXs = {
  inset: '0 auto auto calc((100% + var(--chakra-space-8)) * -1)!important',
  transform: 'translate3d(var(--chakra-space-4), calc(var(--chakra-space-14) + 1px), 0)!important'
};
var menuListRootPropsLessThanSm = {
  inset: '0 auto auto calc((100% + var(--chakra-space-5)) * -1)!important',
  transform: 'translate3d(var(--chakra-space-4), calc(var(--chakra-space-14) + 1px), 0)!important'
};
var menuListRootPropsLessThanMd = {
  inset: '0 0 auto auto!important',
  transform: 'translate3d(0, calc(var(--chakra-space-14) + 1px), 0)!important'
};
var roomLabelStyles = {
  alignSelf: 'start',
  fontWeight: '600',
  fontSize: 'lg',
  textTransform: 'capitalize',
  as: 'h2'
};
var menuItemStyles = {
  height: 'auto',
  px: 'var(--chakra-space-md)',
  _hover: {
    backgroundColor: 'transparent'
  },
  _active: {
    backgroundColor: 'transparent'
  },
  _focus: {
    backgroundColor: 'transparent'
  }
};
var helperTextStyles = {
  color: 'darkGrey2',
  fontSize: 'var(--chakra-fontSizes-xs)',
  mt: '0.375rem!important',
  w: '7.5rem',
  ml: 'var(--chakra-space-md)!important'
};
var cotSwitcherStyles = {
  w: 'full',
  pl: '1rem',
  alignItems: 'center',
  mt: 'var(--chakra-space-xl) !important',
  mb: 'var(--chakra-space-lg) !important'
};
var cotTextStyles = {
  fontSize: 'md',
  lineHeight: '3'
};
var cotLabelStyles = _objectSpread(_objectSpread({}, cotTextStyles), {}, {
  ml: 'md',
  fontWeight: 'bold'
});
var cotLimitStyles = _objectSpread(_objectSpread({}, cotTextStyles), {}, {
  ml: 'sm',
  fontWeight: 'normal'
});
var addAnotherRoomWrapperStyles = {
  _hover: {
    cursor: 'pointer'
  }
};
var addAnotherRoomButtonStyles = {
  border: '0.063rem solid var(--chakra-colors-primary)',
  p: '0.5rem',
  margin: 'var(--chakra-space-lg) 0',
  borderRadius: '50%',
  cursor: 'pointer'
};
var addAnotherRoomLabelStyles = {
  fontSize: 'md',
  fontWeight: 'medium',
  marginLeft: 'md'
};
var doneButtonStyles = {
  _hover: {
    bg: 'baseWhite'
  }
};
var horizontalVariantStyles = () => ({
  '@media screen and (min-width: 1000px)': {
    width: '800px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '80vh',
    '.roompicker-stack': {
      position: 'relative',
      justifyContent: 'normal',
      display: 'block',
      '.roompicker-divider': {
        marginTop: 'md',
        borderColor: 'lightGrey3'
      }
    },
    '.chakra-menu__menuitem': {
      paddingBottom: '6px'
    },
    '.roompicker-occupancy': {
      display: 'inline-block',
      width: 'auto',
      '.chakra-stack': {
        display: 'inline-block',
        paddingRight: 'md',
        '&:last-child': {
          display: 'inline-block',
          paddingRight: '0'
        }
      },
      '.chakra-text': {
        color: 'darkGrey1'
      },
      '.chakra-menu__menu-button': {
        minWidth: '150px'
      }
    },
    '.roompicker-cot-switcher': {
      display: 'inline-block',
      verticalAlign: 'top',
      width: '185px',
      paddingLeft: '0',
      position: 'absolute',
      top: '-1.2rem',
      marginLeft: '260px',
      '.chakra-switch': {
        float: 'left',
        paddingRight: '10px'
      },
      '.chakra-text': {
        fontSize: '15px',
        '&:last-child': {
          fontSize: 'sm',
          paddingLeft: '3.25rem'
        }
      }
    },
    '.roompicker-room-type': {
      width: '230px',
      display: 'inline-block',
      mt: '0',
      verticalAlign: 'top',
      marginLeft: 'md'
    },
    '.roompicker-room-heading': {
      justifyContent: 'normal',
      '.chakra-link': {
        paddingLeft: 'xl'
      }
    },
    '.chakra-button': {
      marginTop: 'lg'
    }
  }
});