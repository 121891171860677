"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DEFAULT_TRACING_COOKIE_NAME", {
  enumerable: true,
  get: function get() {
    return _globalConstants.DEFAULT_TRACING_COOKIE_NAME;
  }
});
Object.defineProperty(exports, "ID_TOKEN_COOKIE", {
  enumerable: true,
  get: function get() {
    return _globalConstants.ID_TOKEN_COOKIE;
  }
});
Object.defineProperty(exports, "RolesRequired", {
  enumerable: true,
  get: function get() {
    return _index.RolesRequired;
  }
});
Object.defineProperty(exports, "TranslationProvider", {
  enumerable: true,
  get: function get() {
    return _i18nClient.TranslationProvider;
  }
});
Object.defineProperty(exports, "cn", {
  enumerable: true,
  get: function get() {
    return _tailwind.cn;
  }
});
exports.executeGraphQLQueries = void 0;
exports.formatHDPUrl = formatHDPUrl;
exports.formatIBAssetsUrl = formatIBAssetsUrl;
exports.getAccessLevel = void 0;
exports.getActivePath = getActivePath;
exports.getLocaleByPathname = exports.getInnBusinessServerSideProps = exports.getInnBusinessHeaderLabels = exports.getInitials = exports.getHotelInformationIB = exports.getHotelAvailabilitiesIB = exports.getFooterLabels = exports.getDaysInMonth = exports.getCountryLanguageByLocale = exports.getCompanyDetails = exports.getCommonIcons = exports.getCardManagementLabels = void 0;
exports.getLocationResults = getLocationResults;
exports.getMultiSearchParamsIB = getMultiSearchParamsIB;
Object.defineProperty(exports, "getPathForLocale", {
  enumerable: true,
  get: function get() {
    return _getPathForLocale.getPathForLocale;
  }
});
exports.isInnBusinessApp = exports.isHotelOpeningSoon = exports.getUserDetails = exports.getTetheredStatus = exports.getServerUnleashToggles = exports.getSearchRedirectLink = exports.getSearchParams = exports.getRandomTracingId = exports.getQueryParams = void 0;
exports.isMoreThan364DaysInFuture = isMoreThan364DaysInFuture;
exports.mapSearchParamsForURL = mapSearchParamsForURL;
exports.roomDefaultValueIfError = roomDefaultValueIfError;
exports.staticHotelInformationIB = exports.roomOccupancyParamsForURL = void 0;
exports.updateSearchParamsIfError = updateSearchParamsIfError;
Object.defineProperty(exports, "useTranslation", {
  enumerable: true,
  get: function get() {
    return _i18nClient.useTranslation;
  }
});
Object.defineProperty(exports, "useTranslationServer", {
  enumerable: true,
  get: function get() {
    return _i18n.useTranslation;
  }
});
exports.validateRoomOccupancy = validateRoomOccupancy;
exports.validateRoomOccupancyConditions = validateRoomOccupancyConditions;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _dateFns = require("date-fns");
var _nanoid = require("nanoid");
var _config = _interopRequireDefault(require("next/config"));
var _headers = require("next/headers");
var _getPathForLocale = require("../formatters/getPathForLocale");
var _globalConstants = require("../global-constants");
var _index = require("../hoc/index");
var _decodeIdToken = _interopRequireDefault(require("../utils/decodeIdToken"));
var _i18n = require("../utils/i18n");
var _i18nClient = require("../utils/i18nClient");
var _tailwind = require("../utils/tailwind");
var _unleash = require("../utils/unleash");
var _validators = require("../validators");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getUserDetails = exports.getUserDetails = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (token) {
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var {
      email
    } = (0, _decodeIdToken.default)(token);
    if (!email) {
      return null;
    }
    try {
      var res = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=true"), {
        method: 'GET',
        headers: {
          Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC,
          Authorization: "Bearer ".concat(token)
        },
        cache: 'no-cache'
      });
      if (!res.ok) {
        return null;
      }
      return yield res.json();
    } catch (error) {
      return null;
    }
  });
  return function getUserDetails(_x) {
    return _ref.apply(this, arguments);
  };
}();
var getAccessLevel = exports.getAccessLevel = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* () {
    var _cookies$get$value, _cookies$get, _userDetails$business;
    var token = (_cookies$get$value = (_cookies$get = (0, _headers.cookies)().get(_globalConstants.ID_TOKEN_COOKIE)) === null || _cookies$get === void 0 ? void 0 : _cookies$get.value) !== null && _cookies$get$value !== void 0 ? _cookies$get$value : '';
    var userDetails = yield getUserDetails(token);
    return userDetails === null || userDetails === void 0 || (_userDetails$business = userDetails.business) === null || _userDetails$business === void 0 ? void 0 : _userDetails$business.accessLevel;
  });
  return function getAccessLevel() {
    return _ref2.apply(this, arguments);
  };
}();
var getTetheredStatus = exports.getTetheredStatus = function () {
  var _ref3 = (0, _asyncToGenerator2.default)(function* () {
    var _cookies$get$value2, _cookies$get2, _userDetails$business2;
    var token = (_cookies$get$value2 = (_cookies$get2 = (0, _headers.cookies)().get(_globalConstants.ID_TOKEN_COOKIE)) === null || _cookies$get2 === void 0 ? void 0 : _cookies$get2.value) !== null && _cookies$get$value2 !== void 0 ? _cookies$get$value2 : '';
    var userDetails = yield getUserDetails(token);
    return (userDetails === null || userDetails === void 0 || (_userDetails$business2 = userDetails.business) === null || _userDetails$business2 === void 0 ? void 0 : _userDetails$business2.tethered) || false;
  });
  return function getTetheredStatus() {
    return _ref3.apply(this, arguments);
  };
}();
var getCompanyDetails = exports.getCompanyDetails = function () {
  var _ref4 = (0, _asyncToGenerator2.default)(function* (companyId, sessionId, employeeId, token) {
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var {
      email
    } = (0, _decodeIdToken.default)(token);
    if (!email || !companyId) {
      return null;
    }
    try {
      var res = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/company/").concat(companyId), {
        method: 'GET',
        headers: {
          'session-id': sessionId,
          'employee-id': employeeId,
          'company-id': companyId,
          Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC,
          Authorization: "Bearer ".concat(token)
        },
        cache: 'no-cache'
      });
      if (!res.ok) {
        return null;
      }
      return yield res.json();
    } catch (error) {
      return null;
    }
  });
  return function getCompanyDetails(_x2, _x3, _x4, _x5) {
    return _ref4.apply(this, arguments);
  };
}();
var executeGraphQLQueries = exports.executeGraphQLQueries = function () {
  var _ref5 = (0, _asyncToGenerator2.default)(function* (query, variables, selector, token) {
    var _process$env$NEXT_APP, _process, _process$env$NEXT_PUB, _process2;
    var expirationTime = (_process$env$NEXT_APP = (_process = process) === null || _process === void 0 || (_process = _process.env) === null || _process === void 0 ? void 0 : _process.NEXT_APP_STATIC_CONTENT_CACHE_TTL) !== null && _process$env$NEXT_APP !== void 0 ? _process$env$NEXT_APP : 0;
    var endpoint = (_process$env$NEXT_PUB = (_process2 = process) === null || _process2 === void 0 || (_process2 = _process2.env) === null || _process2 === void 0 ? void 0 : _process2.NEXT_PUBLIC_GRAPHQL_ENDPOINT) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '';
    try {
      var _result$errors;
      var res = yield fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer ".concat(token !== null && token !== void 0 ? token : '')
        },
        body: JSON.stringify({
          query,
          variables
        }),
        next: {
          revalidate: Number(expirationTime)
        }
      });
      if (!res.ok) {
        return null;
      }
      var result = yield res.json();
      if (!(result !== null && result !== void 0 && result.data) || (result === null || result === void 0 || (_result$errors = result.errors) === null || _result$errors === void 0 ? void 0 : _result$errors.length) > 0) {
        return null;
      }
      return selector(result);
    } catch (error) {
      return null;
    }
  });
  return function executeGraphQLQueries(_x6, _x7, _x8, _x9) {
    return _ref5.apply(this, arguments);
  };
}();
function getLocationResults(_x10) {
  return _getLocationResults.apply(this, arguments);
}
function _getLocationResults() {
  _getLocationResults = (0, _asyncToGenerator2.default)(function* (value) {
    var response = yield fetch("".concat(process.env.NEXT_PUBLIC_SNOWDROP_BASE_URL, "v1/autocomplete?input=/").concat(value, "&gplaces[components]=country:uk|country:de"));
    return response.json();
  });
  return _getLocationResults.apply(this, arguments);
}
var getFooterLabels = exports.getFooterLabels = function () {
  var _ref6 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield executeGraphQLQueries((0, _api.getFooterDetails)(), {
      language,
      country: language === 'en' ? 'gb' : 'de',
      site: 'business-booker'
    }, result => {
      var _result$data;
      return result === null || result === void 0 || (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.footer;
    });
  });
  return function getFooterLabels(_x11) {
    return _ref6.apply(this, arguments);
  };
}();
var getInnBusinessHeaderLabels = exports.getInnBusinessHeaderLabels = function () {
  var _ref7 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield executeGraphQLQueries((0, _api.getInnBusinessHeader)(), {
      language,
      country: language === 'en' ? 'gb' : 'de',
      businessBooker: true
    }, result => {
      var _result$data2;
      return result === null || result === void 0 || (_result$data2 = result.data) === null || _result$data2 === void 0 || (_result$data2 = _result$data2.headerInformation) === null || _result$data2 === void 0 ? void 0 : _result$data2.innBusinessHeader;
    });
  });
  return function getInnBusinessHeaderLabels(_x12) {
    return _ref7.apply(this, arguments);
  };
}();
var getCardManagementLabels = exports.getCardManagementLabels = function () {
  var _ref8 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield executeGraphQLQueries((0, _api.getCardManagementLabels)(), {
      language
    }, result => {
      var _result$data3;
      return result === null || result === void 0 || (_result$data3 = result.data) === null || _result$data3 === void 0 || (_result$data3 = _result$data3.getCardManagementLabels) === null || _result$data3 === void 0 ? void 0 : _result$data3.cardManagementContent;
    });
  });
  return function getCardManagementLabels(_x13) {
    return _ref8.apply(this, arguments);
  };
}();
var getCommonIcons = exports.getCommonIcons = function () {
  var _ref9 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield executeGraphQLQueries((0, _api.getCardManagementLabels)(), {
      language
    }, result => {
      var _result$data4;
      return result === null || result === void 0 || (_result$data4 = result.data) === null || _result$data4 === void 0 || (_result$data4 = _result$data4.getCardManagementLabels) === null || _result$data4 === void 0 ? void 0 : _result$data4.commonIcons;
    });
  });
  return function getCommonIcons(_x14) {
    return _ref9.apply(this, arguments);
  };
}();
var getServerUnleashToggles = exports.getServerUnleashToggles = function () {
  var _ref10 = (0, _asyncToGenerator2.default)(function* (label, fallbackFlags, url, query) {
    var _tracingCookie$name;
    var cookieStore = (0, _headers.cookies)();
    var tracingCookie = cookieStore.get(_globalConstants.DEFAULT_TRACING_COOKIE_NAME);
    return (0, _unleash.getUnleashTogglesServerOrClient)(cookieStore, label, fallbackFlags, url, query, {
      [_globalConstants.DEFAULT_TRACING_COOKIE_NAME]: (_tracingCookie$name = tracingCookie === null || tracingCookie === void 0 ? void 0 : tracingCookie.name) !== null && _tracingCookie$name !== void 0 ? _tracingCookie$name : ''
    });
  });
  return function getServerUnleashToggles(_x15, _x16, _x17, _x18) {
    return _ref10.apply(this, arguments);
  };
}();
var getRandomTracingId = () => {
  return (0, _nanoid.nanoid)();
};
exports.getRandomTracingId = getRandomTracingId;
var getInitials = (firstName, lastName) => {
  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
};
exports.getInitials = getInitials;
function formatIBAssetsUrl() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "https://".concat(process.env.NEXT_PUBLIC_ASSETS_URL).concat(path);
}
function getActivePath(currentPath, navObj) {
  var _Object$values$find$l, _Object$values;
  return (_Object$values$find$l = (_Object$values = Object.values(navObj)) === null || _Object$values === void 0 || (_Object$values = _Object$values.find(value => currentPath === null || currentPath === void 0 ? void 0 : currentPath.includes(value === null || value === void 0 ? void 0 : value.path))) === null || _Object$values === void 0 ? void 0 : _Object$values.label) !== null && _Object$values$find$l !== void 0 ? _Object$values$find$l : '';
}
var getLocaleByPathname = pathname => {
  var segments = pathname.split('/');
  var locale = segments[1].toLowerCase();
  return locale;
};
exports.getLocaleByPathname = getLocaleByPathname;
var getCountryLanguageByLocale = locale => {
  if (locale === _api.CountryCode.DE) {
    return {
      country: _api.CountryCode.DE,
      language: _api.CountryCode.DE
    };
  }
  return {
    country: locale === _api.LOCALES.DE ? _api.CountryCode.DE : _api.CountryCode.GB,
    language: locale === _api.LOCALES.DE ? _api.CountryCode.DE : _api.CountryCode.EN
  };
};
exports.getCountryLanguageByLocale = getCountryLanguageByLocale;
var getSearchParams = () => {
  var url = (0, _headers.headers)().get('WB-Url');
  var {
    searchParams
  } = new URL(url || '');
  return searchParams;
};
exports.getSearchParams = getSearchParams;
var getInnBusinessServerSideProps = exports.getInnBusinessServerSideProps = function () {
  var _ref11 = (0, _asyncToGenerator2.default)(function* (token, language) {
    var needsFooter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    try {
      var _footer;
      var footer;
      if (needsFooter) {
        footer = yield getFooterLabels(language);
      }
      var [userDetails, labels, icons, cards] = yield Promise.all([getUserDetails(token), getInnBusinessHeaderLabels(language), getCommonIcons(language), getCardManagementLabels(language)]);
      var companyDetails = yield getCompanyDetails(userDetails.companyId, userDetails.sessionId, userDetails.employeeId, token);
      return {
        labels,
        icons,
        cards,
        userDetails,
        companyDetails,
        footer: (_footer = footer) !== null && _footer !== void 0 ? _footer : null,
        language
      };
    } catch (_unused) {
      return null;
    }
  });
  return function getInnBusinessServerSideProps(_x19, _x20) {
    return _ref11.apply(this, arguments);
  };
}();
var getHotelInformationIB = exports.getHotelInformationIB = function () {
  var _ref12 = (0, _asyncToGenerator2.default)(function* (hotelId, language) {
    return yield executeGraphQLQueries(_api.GET_HOTEL_INFORMATION, {
      hotelId,
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data5;
      return result === null || result === void 0 || (_result$data5 = result.data) === null || _result$data5 === void 0 ? void 0 : _result$data5.hotelInformation;
    });
  });
  return function getHotelInformationIB(_x21, _x22) {
    return _ref12.apply(this, arguments);
  };
}();
var getHotelAvailabilitiesIB = exports.getHotelAvailabilitiesIB = function () {
  var _ref13 = (0, _asyncToGenerator2.default)(function* (hotelId, arrival, departure, brand, country, rooms, language, bookingChannel, channel, token) {
    return yield executeGraphQLQueries(_api.HOTEL_AVAILABILITY_QUERY, {
      hotelId,
      arrival,
      departure,
      brand,
      country,
      rooms,
      language,
      bookingChannel,
      channel
    }, result => result === null || result === void 0 ? void 0 : result.data, token);
  });
  return function getHotelAvailabilitiesIB(_x23, _x24, _x25, _x26, _x27, _x28, _x29, _x30, _x31, _x32) {
    return _ref13.apply(this, arguments);
  };
}();
var getQueryParams = (selectedDate, location, rooms) => {
  var _selectedDate$from, _selectedDate$to;
  if (!location) return;
  var start = (_selectedDate$from = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.from) !== null && _selectedDate$from !== void 0 ? _selectedDate$from : new Date();
  var end = (_selectedDate$to = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.to) !== null && _selectedDate$to !== void 0 ? _selectedDate$to : (0, _dateFns.add)(new Date(start), {
    days: 1
  });
  var queryParams = {
    searchTerm: location.suggestion,
    ARRdd: start.getDate(),
    ARRmm: start.getMonth() + 1,
    ARRyyyy: start.getFullYear(),
    nights: (0, _dateFns.differenceInDays)(end, start),
    roomsNumber: Object.keys(rooms).length,
    rooms: Object.values(rooms)
  };
  if ('placeId' in location) {
    queryParams.placeId = location.placeId;
  }
  if ('code' in location) {
    queryParams.code = location.code;
  }
  if ('geometry' in location) {
    queryParams.location = location.geometry.coordinates;
  }
  if ('brand' in location) {
    queryParams.brand = location.brand;
  }
  return queryParams;
};
exports.getQueryParams = getQueryParams;
var getSearchRedirectLink = (hotelCode, hotelHasAvailability, hotelIsOpeningSoon, hotelSlug, language, country, URLToRedirect, paramsMappedForURL, queryParams) => {
  var {
    listView
  } = _api.VIEW_TYPE;
  var [longitude = 0, latitude = 0] = queryParams.location || [];
  var location = "".concat(latitude, ",").concat(longitude);
  var baseUrl = "/".concat(country, "/").concat(language, "/business-booker");
  var urlForSrp = "search.html?searchModel.searchTerm";
  if (!hotelHasAvailability || hotelIsOpeningSoon) {
    return "".concat(baseUrl, "/").concat(urlForSrp, "=").concat(paramsMappedForURL.searchTerm, "&LOCATION=").concat(location, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(_api.BOOKING_SUBCHANNEL.CBT, "&SORT=").concat(_api.DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
  }
  if (hotelSlug) {
    return "".concat(baseUrl, "/hotels").concat(hotelSlug, ".html?").concat(formatHDPUrl(URLToRedirect));
  }
  if (!hotelCode) {
    return "".concat(baseUrl, "/").concat(urlForSrp, "=").concat(paramsMappedForURL.searchTerm, "&PLACEID=").concat(queryParams.placeId, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(_api.BOOKING_SUBCHANNEL.CBT, "&SORT=").concat(_api.DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
  }
};
exports.getSearchRedirectLink = getSearchRedirectLink;
function mapSearchParamsForURL(params) {
  var _params$rooms;
  var roomsInputDataMapped = params === null || params === void 0 || (_params$rooms = params.rooms) === null || _params$rooms === void 0 ? void 0 : _params$rooms.map((room, idx) => {
    return {
      ["ADULT".concat(idx + 1)]: room.adults,
      ["CHILD".concat(idx + 1)]: room.children,
      ["COT".concat(idx + 1)]: room.shouldIncludeCot ? 1 : 0
    };
  });
  var roomInputDataObj = roomsInputDataMapped.reduce((prev, current) => _objectSpread(_objectSpread({}, prev), current), {});
  return _objectSpread(_objectSpread({}, params), roomInputDataObj);
}
function formatHDPUrl(redirectURL) {
  if (!redirectURL || redirectURL.indexOf('&') === -1) {
    return redirectURL;
  }
  var checkNoOfPairs = redirectURL.match(/&/g) || [];
  var checkNoOfParams = redirectURL.match(/=/g) || [];
  if ((checkNoOfPairs === null || checkNoOfPairs === void 0 ? void 0 : checkNoOfPairs.length) === 0 || (checkNoOfParams === null || checkNoOfParams === void 0 ? void 0 : checkNoOfParams.length) !== (checkNoOfPairs === null || checkNoOfPairs === void 0 ? void 0 : checkNoOfPairs.length) + 1) {
    return redirectURL;
  }
  return redirectURL.split('&').map(kvPair => {
    if (kvPair.indexOf('ARRdd') > -1 || kvPair.indexOf('ARRmm') > -1) {
      var [key, value] = kvPair.split('=');
      return "".concat(key, "=").concat(('0' + value).slice(-2));
    }
    return kvPair;
  }).join('&');
}
var roomOccupancyParamsForURL = params => {
  var _params$rooms2;
  return params === null || params === void 0 || (_params$rooms2 = params.rooms) === null || _params$rooms2 === void 0 ? void 0 : _params$rooms2.filter(room => room.roomType).map((room, idx) => "ADULT".concat(idx + 1, "=").concat(room.adults, "&CHILD").concat(idx + 1, "=").concat(room.children, "&COT").concat(idx + 1, "=").concat(room.shouldIncludeCot ? 1 : 0, "&INTTYP").concat(idx + 1, "=").concat(room.roomType)).join('&');
};
exports.roomOccupancyParamsForURL = roomOccupancyParamsForURL;
var isHotelOpeningSoon = (hotelOpeningDate, selectedDate) => {
  var openingSoonDate = hotelOpeningDate !== '' && new Date(JSON.parse(JSON.stringify(hotelOpeningDate)));
  return openingSoonDate && (0, _dateFns.differenceInDays)(openingSoonDate, selectedDate) > 0;
};
exports.isHotelOpeningSoon = isHotelOpeningSoon;
var staticHotelInformationIB = exports.staticHotelInformationIB = function () {
  var _ref14 = (0, _asyncToGenerator2.default)(function* (slug, language) {
    return yield executeGraphQLQueries(_api.STATIC_HOTEL_INFORMATION_QUERY, {
      slug,
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data6;
      return result === null || result === void 0 || (_result$data6 = result.data) === null || _result$data6 === void 0 ? void 0 : _result$data6.hotelInformationBySlug;
    });
  });
  return function staticHotelInformationIB(_x33, _x34) {
    return _ref14.apply(this, arguments);
  };
}();
function getMultiSearchParamsIB(searchParamsObj) {
  if (!searchParamsObj) return {};
  var {
    ARRdd,
    ARRmm,
    ARRyyyy,
    NIGHTS,
    ROOMS,
    CELLCODES = ''
  } = searchParamsObj;
  if (!ARRdd && !ARRmm && !ARRyyyy && !(0, _validators.isDateValid)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy))) {
    return {
      arrival: undefined,
      departure: undefined,
      rooms: []
    };
  }
  if (!ARRdd || !ARRmm || !ARRyyyy || !NIGHTS || !ROOMS || !(0, _validators.isDateValid)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy))) return {
    arrival: (0, _dateFns.format)((0, _dateFns.endOfDay)(new Date()), 'yyyy-MM-dd'),
    departure: (0, _dateFns.format)((0, _dateFns.endOfDay)((0, _dateFns.add)(new Date(), {
      days: Number(NIGHTS) || 1
    })), 'yyyy-MM-dd'),
    numberOfNights: Number(NIGHTS) || 1,
    rooms: [{
      adults: 1,
      children: 0,
      roomType: _api.ROOM_CODES.double,
      shouldIncludeCot: false
    }]
  };
  var arrival = new Date(+ARRyyyy, +ARRmm - 1, +ARRdd);
  var departure = (0, _dateFns.add)(arrival, {
    days: +NIGHTS
  });
  var rooms = [];
  for (var idx = 0; idx < +ROOMS; idx++) {
    rooms.push({
      adults: +searchParamsObj["ADULT".concat(idx + 1)],
      children: +searchParamsObj["CHILD".concat(idx + 1)],
      roomType: searchParamsObj["INTTYP".concat(idx + 1)],
      shouldIncludeCot: searchParamsObj["COT".concat(idx + 1)] === '1'
    });
  }
  return {
    arrival: (0, _dateFns.format)(arrival, 'yyyy-MM-dd'),
    departure: (0, _dateFns.format)(departure, 'yyyy-MM-dd'),
    numberOfUnits: +ROOMS,
    numberOfNights: +NIGHTS,
    rooms,
    cellCodes: String(CELLCODES)
  };
}
function updateSearchParamsIfError(searchParams, userRole, router) {
  var nights = searchParams.get(_api.URLParams.nights);
  var day = searchParams.get(_api.URLParams.day);
  var month = searchParams.get(_api.URLParams.month);
  var year = searchParams.get(_api.URLParams.year);
  var searchParamsObj = {};
  searchParams.forEach((value, key) => {
    searchParamsObj[key] = value;
  });
  var startDate = new Date(Number(year), Number(month) && Number(month) - 1, Number(day));
  var today = (0, _dateFns.startOfDay)(new Date());
  var shouldUpdate = false;
  var newSearchParams = new URLSearchParams(searchParams.toString());
  var daysInMonth = getDaysInMonth(Number(month), Number(year));
  var rooms = Number(searchParamsObj['ROOMS']);
  var resetRoomToDefault = () => {
    var resetRoomParamsToDefault = {
      [_api.URLParams.rooms]: '1',
      ["".concat(_api.URLParams.adult, "1")]: '1',
      ["".concat(_api.URLParams.child, "1")]: '0',
      ["".concat(_api.URLParams.cot, "1")]: '0',
      ["".concat(_api.URLParams.roomType, "1")]: _api.ROOM_CODES.double
    };
    setSearchParams(newSearchParams, resetRoomParamsToDefault);
    resetExtraRooms(newSearchParams, 2);
    shouldUpdate = true;
  };
  if (searchParamsObj['ROOMS'] && (Number.isNaN(rooms) || rooms < _api.DEFAULT_NUMBER || rooms > _api.MAX_ROOMS_ALLOWED || userRole === _api.BUSINESS_BOOKER_USER_ROLES.SELF && rooms > _api.DEFAULT_NUMBER)) {
    resetRoomToDefault();
  } else if (searchParamsObj['ROOMS']) {
    for (var roomIndex = 1; roomIndex <= rooms; roomIndex++) {
      var {
        adult,
        child,
        cot,
        roomType
      } = getSearchDetails(roomIndex, searchParams);
      if (!adult || !child || !cot || !roomType) {
        resetRoomToDefault();
        break;
      }
    }
  }
  for (var _roomIndex = 1; _roomIndex <= rooms; _roomIndex++) {
    var adultKey = "".concat(_api.URLParams.adult).concat(_roomIndex);
    var childKey = "".concat(_api.URLParams.child).concat(_roomIndex);
    var cotKey = "".concat(_api.URLParams.cot).concat(_roomIndex);
    var roomTypeKey = "".concat(_api.URLParams.roomType).concat(_roomIndex);
    var {
      adult: _adult,
      child: _child,
      cot: _cot,
      roomType: _roomType
    } = getSearchDetails(_roomIndex, searchParams);
    var allConditions = validateRoomOccupancyConditions(_adult, _child, _roomType, _cot);
    if (_adult && _child && _cot && _roomType && allConditions) {
      var resetRoomOccupancyParams = {
        [adultKey]: '1',
        [childKey]: '0',
        [cotKey]: '0',
        [roomTypeKey]: _api.ROOM_CODES.double
      };
      setSearchParams(newSearchParams, resetRoomOccupancyParams);
      for (var _roomIndex2 = 2; _roomIndex2 < 5; _roomIndex2++) {
        newSearchParams.delete("".concat(_api.URLParams.adult).concat(_roomIndex2));
        newSearchParams.delete("".concat(_api.URLParams.child).concat(_roomIndex2));
        newSearchParams.delete("".concat(_api.URLParams.cot).concat(_roomIndex2));
        newSearchParams.delete("".concat(_api.URLParams.roomType).concat(_roomIndex2));
      }
      newSearchParams.set(_api.URLParams.rooms, '1');
      shouldUpdate = true;
    }
  }
  if (nights && (Number.isNaN(Number(nights)) || Number(nights) > _api.MAX_NUMBER_OF_NIGHTS_IB || Number(nights) < _api.DEFAULT_NUMBER)) {
    newSearchParams.set(_api.URLParams.nights, '1');
    shouldUpdate = true;
  }
  if (year && month && day && ((0, _dateFns.isBefore)(startDate, today) || isMoreThan364DaysInFuture(startDate, today) || isNaN(Number(year)) || isNaN(Number(month)) || isNaN(Number(day)) || Number(month) < _api.DEFAULT_NUMBER || Number(month) > _api.MAX_NUMBER_OF_MONTHS || Number(day) < _api.DEFAULT_NUMBER || Number(day) > daysInMonth)) {
    var resetDateParams = {
      [_api.URLParams.nights]: '1',
      [_api.URLParams.day]: today.getDate().toString(),
      [_api.URLParams.month]: (today.getMonth() + 1).toString(),
      [_api.URLParams.year]: today.getFullYear().toString()
    };
    setSearchParams(newSearchParams, resetDateParams);
    shouldUpdate = true;
  }
  if (shouldUpdate) {
    var newUrl = "".concat(window.location.pathname, "?").concat(newSearchParams.toString());
    router.replace(newUrl);
  }
}
function isMoreThan364DaysInFuture(startDate, today) {
  var dayInFuture = (0, _dateFns.addDays)(today, 364);
  return (0, _dateFns.isAfter)(startDate, dayInFuture);
}
function roomDefaultValueIfError(rooms) {
  var defaultValue = {
    adultsNumber: _api.DEFAULT_NUMBER,
    childrenNumber: 0,
    type: _api.ROOM_CODES.double
  };
  if (rooms.length === 0) {
    return [_objectSpread({}, defaultValue)];
  }
  var hasInvalidValue = rooms.some(room => {
    return validateRoomOccupancyConditions(room.adultsNumber.toString(), room.childrenNumber.toString(), room.type);
  });
  if (hasInvalidValue) {
    return [_objectSpread({}, defaultValue)];
  }
  return rooms;
}
function validateRoomOccupancy(roomIndex, searchParamsObj) {
  var adultKey = "".concat(_api.URLParams.adult).concat(roomIndex);
  var childKey = "".concat(_api.URLParams.child).concat(roomIndex);
  var cotKey = "".concat(_api.URLParams.cot).concat(roomIndex);
  var roomTypeKey = "".concat(_api.URLParams.roomType).concat(roomIndex);
  var isAdultMissing = searchParamsObj[adultKey] === undefined;
  var isChildMissing = searchParamsObj[childKey] === undefined;
  var isCotMissing = searchParamsObj[cotKey] === undefined;
  var isRoomTypeMissing = searchParamsObj[roomTypeKey] === undefined;
  if (isAdultMissing || isChildMissing || isCotMissing || isRoomTypeMissing) {
    return true;
  }
  var isAdultValid = !isNaN(Number(searchParamsObj[adultKey]));
  var isChildValid = !isNaN(Number(searchParamsObj[childKey]));
  var isCotValid = !isNaN(Number(searchParamsObj[cotKey]));
  var isRoomTypeValid = searchParamsObj[roomTypeKey] !== '';
  var allValid = isAdultValid && isChildValid && isCotValid && isRoomTypeValid;
  return !allValid;
}
function validateRoomOccupancyConditions(adult, child, roomType, cot) {
  return Number.isNaN(Number(adult)) || Number(adult) < _api.DEFAULT_NUMBER || Number(adult) > 2 || Number(adult) > _api.DEFAULT_NUMBER && roomType === _api.ROOM_CODES.single || Number(adult) === _api.DEFAULT_NUMBER && roomType === _api.ROOM_CODES.twin || Number.isNaN(Number(child)) || Number(child) < 0 || Number(child) > 2 || Number(child) === 0 && roomType === _api.ROOM_CODES.family || Number(child) > 0 && roomType !== _api.ROOM_CODES.family || cot !== null && cot !== undefined && (Number.isNaN(Number(cot)) || Number(cot) < 0 || Number(cot) > _api.DEFAULT_NUMBER) || roomType !== _api.ROOM_CODES.single && roomType !== _api.ROOM_CODES.double && roomType !== _api.ROOM_CODES.family && roomType !== _api.ROOM_CODES.twin && roomType !== _api.ROOM_CODES.accessible;
}
var resetExtraRooms = (searchParams, fromRoomIndex) => {
  for (var roomIndex = fromRoomIndex; roomIndex <= _api.MAX_ROOMS_ALLOWED; roomIndex++) {
    searchParams.delete("".concat(_api.URLParams.adult).concat(roomIndex));
    searchParams.delete("".concat(_api.URLParams.child).concat(roomIndex));
    searchParams.delete("".concat(_api.URLParams.cot).concat(roomIndex));
    searchParams.delete("".concat(_api.URLParams.roomType).concat(roomIndex));
  }
};
var getSearchDetails = (roomIndex, searchParams) => {
  var getParam = key => searchParams.get(key);
  var adultKey = "".concat(_api.URLParams.adult).concat(roomIndex);
  var childKey = "".concat(_api.URLParams.child).concat(roomIndex);
  var cotKey = "".concat(_api.URLParams.cot).concat(roomIndex);
  var roomTypeKey = "".concat(_api.URLParams.roomType).concat(roomIndex);
  return {
    adult: getParam(adultKey),
    child: getParam(childKey),
    cot: getParam(cotKey),
    roomType: getParam(roomTypeKey)
  };
};
var setSearchParams = (newSearchParams, paramValues) => {
  Object.keys(paramValues).forEach(key => {
    var value = paramValues[key];
    newSearchParams.set(key, value);
  });
};
var getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};
exports.getDaysInMonth = getDaysInMonth;
var isInnBusinessApp = hostname => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return hostname && hostname.includes('innbusiness');
};
exports.isInnBusinessApp = isInnBusinessApp;