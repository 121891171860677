"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomModalComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _constants = require("../../search/RoomPicker/constants");
var _BBLeadGuestDetails = require("../BBLeadGuestDetails");
var _LeadGuestDetails = require("../LeadGuestDetails");
var _constants2 = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomModalComponent(_ref) {
  var {
    isOpen,
    onCloseModal,
    title,
    roomRules,
    onAvailabilityCheck,
    onChange: _onChange,
    onAddNewRoom,
    onUpdateRoom,
    getFormState,
    isAvailabilityButtonEnabled,
    isHotelAvailable,
    roomDetails,
    labels,
    baseDataTestId,
    roomTypeOptions,
    roomOccupancyDetails,
    isEdit,
    roomNumber,
    leadGuestDetails,
    setGuestDetails,
    setIsLocationRequired,
    isUpdateBtnDisabled,
    variant,
    bbEmployeeList,
    isAdultsDecreased,
    hotelCountry,
    language,
    isBbGuestEdited,
    setIsBbGuestEdited
  } = _ref;
  var {
    roomAvailabilityLabels,
    leadGuestLabels,
    leadGuestValidationLabels,
    notificationLabels
  } = labels;
  var {
    displayNotification,
    available
  } = isHotelAvailable;
  var {
    children,
    adults,
    roomType
  } = roomDetails;
  var {
    displayRoomOccupancy,
    price,
    guests
  } = roomOccupancyDetails;
  var [bbEditLeadGuestDetails, setBbEditLeadGuestDetails] = (0, _react2.useState)();
  var isBb = variant === _api.Area.BB;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var adultsPlaceholder = "".concat(adults, " ").concat(adults > 1 ? roomAvailabilityLabels.adults : roomAvailabilityLabels.adult);
  var onSubmit = guestDetails => {
    isEdit ? onUpdateRoom(guestDetails) : onAddNewRoom(guestDetails);
    onCloseModal();
  };
  var prefixDataTestId = 'add-room-modal';
  var initialBbLeadGuestDetailsData = {
    bbGuestDetails: [{
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      id: '',
      composedName: ''
    }]
  };
  var selectedGuest = (0, _react2.useMemo)(() => {
    var _bbEmployeeList$find;
    var selectedGuest = bbEmployeeList === null || bbEmployeeList === void 0 || (_bbEmployeeList$find = bbEmployeeList.find(item => item.employee.firstName === leadGuestDetails.firstName && item.employee.lastName === leadGuestDetails.lastName && item.employee.title === leadGuestDetails.title && item.employee.emailAddress === leadGuestDetails.email)) === null || _bbEmployeeList$find === void 0 ? void 0 : _bbEmployeeList$find.employee;
    if (!selectedGuest) {
      selectedGuest = {
        firstName: leadGuestDetails.firstName || '',
        lastName: leadGuestDetails.lastName || '',
        title: leadGuestDetails.title || '',
        emailAddress: leadGuestDetails.emailAddress || ''
      };
    }
    return selectedGuest;
  }, [bbEmployeeList, leadGuestDetails]);
  (0, _react2.useEffect)(() => {
    if (isEdit && variant === _api.Area.BB) {
      setBbEditLeadGuestDetails(selectedGuest);
      setBbLeadGuestDetails({
        bbGuestDetails: [selectedGuest]
      });
    }
  }, [isEdit, leadGuestDetails, bbEmployeeList, variant, selectedGuest]);
  var [bbLeadGuestDetails, setBbLeadGuestDetails] = (0, _react2.useState)(initialBbLeadGuestDetailsData);
  function setGuestUser(user, index) {
    var tempList = bbLeadGuestDetails;
    if (tempList.bbGuestDetails && tempList.bbGuestDetails[index]) {
      tempList.bbGuestDetails[index] = user;
      setBbLeadGuestDetails(tempList);
    }
  }
  var onBbSubmit = guestDetails => {
    isEdit ? onUpdateRoom(guestDetails === null || guestDetails === void 0 ? void 0 : guestDetails.bbGuestDetails[0]) : onAddNewRoom(guestDetails === null || guestDetails === void 0 ? void 0 : guestDetails.bbGuestDetails[0]);
    onCloseModal();
    setBbLeadGuestDetails(initialBbLeadGuestDetailsData);
  };
  var isUpdateDisabled = (0, _react2.useCallback)(() => {
    if (variant === _api.Area.PI || variant === _api.Area.CCUI) {
      return isUpdateBtnDisabled;
    }
    if (isEdit) {
      return !(isBbGuestEdited || isHotelAvailable.available);
    }
    return !isHotelAvailable.available;
  }, [variant, isUpdateBtnDisabled, isHotelAvailable, isBbGuestEdited, isEdit]);
  function onEditBbInput(data) {
    if ('key' in data) {
      var {
        key,
        value
      } = data;
      setBbEditLeadGuestDetails(prevValue => {
        return _objectSpread(_objectSpread({}, prevValue), {}, {
          [key]: value
        });
      });
    } else {
      setBbEditLeadGuestDetails(data);
    }
    setIsBbGuestEdited(true);
  }
  var resetMealsAfterEditRoomLabel = notificationLabels.description.replace('[number]', "".concat(roomNumber));
  return (0, _jsxRuntime.jsxs)(_atoms.ModalVariants, {
    isOpen: isOpen,
    onClose: onCloseModal,
    variant: "default",
    contentContainerStyles: {
      overflow: 'auto'
    },
    variantProps: {
      title: '',
      delimiter: true
    },
    headerStyles: {
      textAlign: 'center',
      justifyContent: 'center'
    },
    updatedWidth: {
      md: 'auto',
      lg: '50.5rem',
      xl: '61rem'
    },
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h2",
      "data-testid": "".concat(prefixDataTestId, "-title")
    }, headingStyles), {}, {
      fontSize: "lg",
      textAlign: "center",
      mt: "lg",
      children: title
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      justifyContent: "space-between",
      gap: {
        base: 'md',
        sm: 'lg'
      },
      direction: {
        base: 'column',
        sm: 'row'
      }
    }, modalContentStyles), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        direction: "column"
      }, columnWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h3",
          "data-testid": "".concat(prefixDataTestId, "-guests-title")
        }, headingStyles), {}, {
          children: roomAvailabilityLabels.guests
        })), (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "row",
          mb: "md",
          gap: {
            base: 'md',
            md: 'sm'
          },
          children: [(0, _jsxRuntime.jsx)(_atoms.Dropdown, {
            dataTestId: "".concat(prefixDataTestId, "-adults-number"),
            onChange: chosenOption => {
              _onChange(chosenOption, _constants2.ADULTS_PARAM);
            },
            options: (0, _utils.createOptionsAdultsChildrenDropdown)((0, _utils.getAdultsChildrenOptions)(roomRules, _constants2.ADULTS_NUMBER_KEY), roomAvailabilityLabels.adult, roomAvailabilityLabels.adults),
            placeholder: adultsPlaceholder,
            matchWidth: true,
            dropdownStyles: _objectSpread({}, dropdownStyles)
          }), (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
            dataTestId: "".concat(prefixDataTestId, "-children-number"),
            onChange: chosenOption => _onChange(chosenOption, _constants2.CHILDREN_PARAM),
            options: (0, _utils.createOptionsAdultsChildrenDropdown)((0, _utils.getAdultsChildrenOptions)(roomRules, _constants.CHILDREN_NUMBER_KEY), roomAvailabilityLabels.child, roomAvailabilityLabels.children),
            placeholder: "".concat(children, " ").concat(roomAvailabilityLabels.children),
            matchWidth: true,
            dropdownStyles: _objectSpread({}, dropdownStyles)
          })]
        }), (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
          dataTestId: "".concat(prefixDataTestId, "-room-type"),
          onChange: chosenOption => _onChange(chosenOption, _constants.ROOM_TYPE_KEY),
          options: roomTypeOptions,
          placeholder: roomDetails.roomType,
          selectedId: roomDetails.roomType,
          matchWidth: true,
          dropdownStyles: _objectSpread({}, dropdownStyles)
        }), (0, _jsxRuntime.jsx)(_react.Button, {
          size: "full",
          variant: "primary",
          "data-testid": "".concat(prefixDataTestId, "-availability-button"),
          mt: "md",
          mb: "xmd",
          isDisabled: !isAvailabilityButtonEnabled,
          onClick: onAvailabilityCheck,
          children: roomAvailabilityLabels.checkRoomAvailability
        }), displayNotification && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
          children: available ? (0, _jsxRuntime.jsx)(_atoms.Notification, {
            status: "success",
            variant: "success",
            svg: (0, _jsxRuntime.jsx)(_atoms.Success, {}),
            description: roomAvailabilityLabels.roomAvailable,
            "data-testid": "".concat(prefixDataTestId, "-room-available-success")
          }) : (0, _jsxRuntime.jsx)(_atoms.Notification, {
            title: roomAvailabilityLabels.roomsUnavailable,
            status: "error",
            variant: "error",
            svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
            description: roomAvailabilityLabels.roomsUnavailableDescription,
            "data-testid": "".concat(prefixDataTestId, "-room-unavailable-error")
          })
        }), isEdit && isAdultsDecreased && available && (0, _jsxRuntime.jsx)(_react.Box, {
          mt: "xmd",
          children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
            status: "warning",
            variant: "alert",
            svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
            title: notificationLabels.title,
            description: resetMealsAfterEditRoomLabel,
            "data-testid": "".concat(prefixDataTestId, "-room-warning-notification")
          })
        })]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        direction: "column"
      }, columnWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
          as: "h3",
          "data-testid": "".concat(prefixDataTestId, "-lead-guest-title")
        }, _objectSpread(_objectSpread({}, leadGuestHeadingStyles), {}, {
          mb: isBb ? '0' : 'md'
        })), {}, {
          children: roomAvailabilityLabels.leadGuest
        })), isBb ? (0, _jsxRuntime.jsx)(_BBLeadGuestDetails.BBLeadGuestDetails, {
          onSubmit: onBbSubmit,
          numberOfRooms: 1,
          labels: leadGuestLabels,
          validationLabels: leadGuestValidationLabels,
          isDynamicSearchVisible: true,
          guestList: bbLeadGuestDetails,
          setGuestUser: setGuestUser,
          getFormState: getFormState,
          queryClient: queryClient,
          isEdit: isEdit,
          bbEmployeeList: bbEmployeeList,
          defaultGuest: bbEditLeadGuestDetails,
          onEditBbInput: onEditBbInput
        }) : (0, _jsxRuntime.jsx)(_LeadGuestDetails.LeadGuestDetails, {
          labels: leadGuestLabels,
          validationLabels: labels.leadGuestValidationLabels,
          baseDataTestId: baseDataTestId,
          getFormState: getFormState,
          leadGuestDetails: leadGuestDetails,
          onSubmit: onSubmit,
          isEdit: isEdit,
          setGuestDetails: setGuestDetails,
          setIsLocationRequired: setIsLocationRequired,
          hotelCountry: hotelCountry,
          language: language
        }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), displayRoomOccupancy && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
            children: [(0, _jsxRuntime.jsxs)(_react.Text, {
              color: "darkGrey2",
              "data-testid": "".concat(prefixDataTestId, "-number-of-guests-room-type"),
              children: [guests, " ", (0, _jsxRuntime.jsx)("b", {
                children: roomType
              })]
            }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceStyle), {}, {
              "data-testid": "".concat(prefixDataTestId, "-price"),
              children: price
            }))]
          }), (hotelCountry === _constants2.CITYTAX_HOTEL_COUNTRY_EN || hotelCountry === _constants2.CITYTAX_HOTEL_COUNTRY_DE) && (0, _jsxRuntime.jsx)(_react.Flex, {
            justifyContent: "flex-end",
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              textAlign: "right",
              as: "span"
            }, cityTaxNotIncludedStyle), {}, {
              "data-testid": "".concat(prefixDataTestId, "-cityTaxNotIncluded"),
              children: roomAvailabilityLabels.cityTaxNotIncluded
            }))
          }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles))]
        }), (0, _jsxRuntime.jsx)(_react.Button, {
          size: "full",
          variant: "secondary",
          "data-testid": "add-edit-room-button",
          form: "leadGuestDetailsForm",
          type: "submit",
          isDisabled: isUpdateDisabled(),
          children: isEdit ? roomAvailabilityLabels.update : roomAvailabilityLabels.addRoom
        }), (0, _jsxRuntime.jsx)(_react.Button, {
          size: "full",
          variant: "tertiary",
          "data-testid": "add-room-close-button",
          mt: "md",
          onClick: () => {
            setBbEditLeadGuestDetails(selectedGuest);
            setBbLeadGuestDetails({
              bbGuestDetails: [selectedGuest]
            });
            onCloseModal();
          },
          children: roomAvailabilityLabels.cancelBtn
        })]
      }))]
    }))]
  });
}
var modalContentStyles = {
  p: {
    base: 'md',
    md: 'lg',
    xl: '2xl'
  },
  pb: {
    lg: 'xl',
    xl: 'xl'
  },
  mt: 'xl',
  w: {
    base: 'full',
    md: '48rem',
    lg: '50.5rem',
    xl: '61rem'
  }
};
var columnWrapperStyles = {
  w: {
    base: 'full',
    sm: '48rem',
    md: 'full'
  },
  maxW: {
    md: '48rem',
    xl: '27.25rem'
  }
};
var headingStyles = {
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: '3',
  mb: 'md',
  color: 'darkGrey1'
};
var dropdownStyles = {
  menuButtonStyles: {
    borderColor: 'lightGrey1'
  }
};
var leadGuestHeadingStyles = _objectSpread({
  mt: {
    mobile: 'var(--chakra-space-lg)',
    sm: 0
  }
}, headingStyles);
var dividerStyles = {
  my: 'xl',
  color: 'lightGrey1',
  border: '1px solid'
};
var priceStyle = {
  pl: '1rem',
  color: 'darkGrey1',
  fontSize: 'xl'
};
var cityTaxNotIncludedStyle = {
  w: '32%',
  color: 'darkGrey1',
  fontSize: 'sm'
};