"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomsMealSelection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _MealSelection = _interopRequireDefault(require("../../ancillaries/MealSelection"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomsMealSelection(props) {
  var _firstRoom$roomStay, _firstRoom$roomStay2;
  var {
    rooms,
    mealPackagesData,
    restaurantLogo,
    onSaveReservation,
    handleOnAddMeal,
    handleOnRemoveMeal,
    currentRoom,
    saveMealsAllAtOnce = false
  } = props;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var baseDataTestId = 'RoomsMealSelection';
  var [selectedMeals, setSelectedMeals] = (0, _react2.useState)([]);
  var [selectedRoom, setSelectedRoom] = (0, _react2.useState)(currentRoom);
  var mealsTabs = rooms.map((room, index) => {
    var _room$roomStay;
    return {
      index,
      label: "".concat(t('account.dashboard.room'), " ").concat(index + 1),
      description: room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 || (_room$roomStay = _room$roomStay.roomExtraInfo) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.roomName
    };
  });
  (0, _react2.useEffect)(() => {
    var newMeals = (0, _utils.mealsMapperSelector)(mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.meals, mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.mealsKids, mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.roomSelection);
    setSelectedMeals(newMeals);
  }, [mealPackagesData]);
  var firstRoom = rooms[0] || {};
  var noNights = (0, _utils.getNightsNumber)((_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.arrivalDate, (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.departureDate);
  var bookingInformation = getBookingDetails(rooms, noNights);
  var adultsMeals = (0, _utils.adultsMealsSelector)(mealPackagesData.meals, noNights, bookingInformation.totalAdults);
  var childrenMeals = (0, _utils.childrenMealsSelector)(mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.mealsKids);
  var menus = mealPackagesData !== null && mealPackagesData !== void 0 && mealPackagesData.meals && mealPackagesData !== null && mealPackagesData !== void 0 && mealPackagesData.mealsKids ? (0, _utils.menusSelector)(mealPackagesData.meals, mealPackagesData.mealsKids) : [];
  var resvHasChildrenBreakfast = bookingInformation.totalChildren > 0 && adultsMeals.some(meal => meal.freeBreakfastOption) && (0, _utils.freeBreakfastMaxAllowance)(adultsMeals) > 0;
  var showFreeFoodKids = bookingInformation.children[selectedRoom] > 0 && adultsMeals.some(meal => meal.freeBreakfastOption) && (0, _utils.freeBreakfastMaxAllowance)(adultsMeals) > 0;
  var entireSelectionTitle = t('upsell.meals.title.allRooms');
  var individualSelectionTitle = t('upsell.meals.title.addMeals');
  var hasMenus = (menus === null || menus === void 0 ? void 0 : menus.length) > 0;
  return (0, _jsxRuntime.jsx)(_react.GridItem, _objectSpread(_objectSpread({}, mealsContainerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Meals'),
    children: mealsTabs.length > 1 && rooms.length > 1 ? (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [saveMealsAllAtOnce && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_MealSelection.default, {
          headingTitle: entireSelectionTitle,
          logoRestaurantUrl: (0, _utils.formatAssetsUrl)(restaurantLogo, true),
          adultsPerRoom: bookingInformation.adults,
          kidsPerRoom: bookingInformation.children,
          adults: bookingInformation.totalAdults,
          nights: bookingInformation.nrNights,
          kids: bookingInformation.totalChildren,
          adultsMeals: adultsMeals,
          childrenMeals: childrenMeals,
          selectedRoom: selectedRoom,
          selectedMeals: selectedMeals,
          setSelectedMeals: setSelectedMeals,
          showFreeFoodKids: resvHasChildrenBreakfast,
          prefixDataTestId: baseDataTestId,
          hasMenus: hasMenus,
          isForEntireStay: true,
          showUpdateMealsButton: true,
          onSaveReservation: onSaveReservation
        }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyle)), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, individualMealsTitle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Individual-Room-Title'),
          children: t('upsell.meals.title.individualRoom')
        }))]
      }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, multiRoomsContainerStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Tabs, {
          options: mealsTabs,
          variant: "greyTabsGroup",
          index: selectedRoom,
          onChange: setSelectedRoom,
          prefixDataTestId: baseDataTestId,
          shortMobileLabels: true,
          singleContent: renderMultiRoomMealsSelection()
        })
      }))]
    }) : (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, singleRoomContainerStyle), {}, {
      children: renderMealsSelection(firstRoom.reservationId)
    }))
  }));
  function renderMealsSelection(reservationId) {
    var hasMenus = (menus === null || menus === void 0 ? void 0 : menus.length) > 0;
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_MealSelection.default, {
        headingTitle: individualSelectionTitle,
        logoRestaurantUrl: (0, _utils.formatAssetsUrl)(restaurantLogo, true),
        adults: bookingInformation.adults[selectedRoom],
        nights: bookingInformation.nrNights,
        kids: bookingInformation.children[selectedRoom],
        adultsMeals: adultsMeals,
        childrenMeals: childrenMeals,
        selectedRoom: selectedRoom,
        selectedMeals: selectedMeals,
        setSelectedMeals: setSelectedMeals,
        showFreeFoodKids: showFreeFoodKids,
        prefixDataTestId: baseDataTestId,
        hasMenus: hasMenus,
        onSaveReservation: !saveMealsAllAtOnce ? onSaveReservation : undefined,
        onAddMeal: handleOnAddMeal,
        onRemoveMeal: handleOnRemoveMeal,
        reservationId: reservationId
      }), hasMenus && (0, _jsxRuntime.jsx)(_molecules.Menus, {
        availableMenus: menus,
        prefixDataTestId: baseDataTestId
      })]
    });
  }
  function renderMultiRoomMealsSelection() {
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, multiRoomMealsSelectionContainerStyle), {}, {
      "data-testid": "multipleRoom",
      children: renderMealsSelection(rooms[selectedRoom].reservationId)
    }));
  }
}
function getBookingDetails(rooms, noNights) {
  var noAdults = [];
  var noChildren = [];
  var totalAdults = 0;
  var totalChildren = 0;
  rooms.forEach(room => {
    totalAdults += room.roomStay.adultsNumber;
    noAdults.push(room.roomStay.adultsNumber);
    totalChildren += room.roomStay.childrenNumber;
    noChildren.push(room.roomStay.childrenNumber);
  });
  return {
    adults: noAdults,
    children: noChildren,
    nrNights: noNights,
    totalChildren: totalChildren,
    totalAdults: totalAdults
  };
}
var dividerStyle = {
  my: '3xl',
  borderColor: 'lightGrey1'
};
var individualMealsTitle = {
  mb: 'xl',
  fontWeight: 'semibold',
  fontSize: {
    mobile: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    mobile: '3',
    sm: '4'
  },
  color: 'var(--chakra-colors-darkGrey1)'
};
var singleRoomContainerStyle = {
  mt: 0,
  pt: 0
};
var multiRoomsContainerStyle = {
  border: '1px solid var(--chakra-colors-lightGrey2)',
  boxShadow: '0 0 var(--chakra-space-xmd) var(--chakra-colors-lightGrey4)',
  borderRadius: '0 0 3px 3px',
  borderTop: 'none'
};
var multiRoomMealsSelectionContainerStyle = {
  px: {
    mobile: 'md',
    sm: 'lg'
  },
  pt: {
    mobile: 'lg',
    sm: 'xl',
    lg: '3xl'
  },
  pb: {
    mobile: 'md',
    sm: 'lg'
  }
};
var mealsContainerStyle = {
  px: 'var(--chakra-space-lg)',
  py: 'var(--chakra-space-2xl)'
};