"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translate = translate;
exports.useTranslation = useTranslation;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _server = require("../server");
function getDictionary(language, namespace) {
  if (namespace === 'common') {
    return (0, _server.getInnBusinessHeaderLabels)(language);
  }
  if (namespace === 'cards') {
    return (0, _server.getCardManagementLabels)(language);
  }
  if (namespace === 'footer') {
    return (0, _server.getFooterLabels)(language);
  }
  return undefined;
}
function translate(translations, key, namespace) {
  var _value;
  var value = translations;
  if (value && namespace) {
    value = translations[namespace];
  }
  var tokens = key.split('.');
  while (value && tokens.length) {
    var token = tokens.shift();
    if (!token) {
      break;
    }
    value = value[token];
  }
  return (_value = value) !== null && _value !== void 0 ? _value : key;
}
function useTranslation(_x) {
  return _useTranslation.apply(this, arguments);
}
function _useTranslation() {
  _useTranslation = (0, _asyncToGenerator2.default)(function* (language) {
    var namespace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'common';
    var translations = {};
    if (typeof namespace === 'string') {
      translations[namespace] = yield getDictionary(language, namespace);
      return {
        t: key => translate(translations, key, namespace)
      };
    }
    var namespaces = yield Promise.all(namespace.map(x => getDictionary(language, x)));
    namespace.forEach((x, i) => {
      translations[x] = namespaces[i];
    });
    return {
      t: key => translate(translations, key)
    };
  });
  return _useTranslation.apply(this, arguments);
}