"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_ROOM_PICKER = exports.ONE_MINUTE = exports.MARKETING_OPT_IN = exports.DIGITAL_REG_CARD = exports.BILLING_ADDRESS_CAPTURE_VARIANT = exports.BILLING_ADDRESS_CAPTURE = void 0;
var MARKETING_OPT_IN = exports.MARKETING_OPT_IN = 'marketingOptIn';
var BILLING_ADDRESS_CAPTURE = exports.BILLING_ADDRESS_CAPTURE = 'billing_address_capture';
var BILLING_ADDRESS_CAPTURE_VARIANT = exports.BILLING_ADDRESS_CAPTURE_VARIANT = 'variant';
var DIGITAL_REG_CARD = exports.DIGITAL_REG_CARD = 'digital_reg_card';
var SEARCH_ROOM_PICKER = exports.SEARCH_ROOM_PICKER = 'search_room_picker';
var ONE_MINUTE = exports.ONE_MINUTE = 1;