"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = UserProfile;
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _config = _interopRequireDefault(require("next/config"));
var _react = require("react");
var _AnonRFS = require("../AnonRFS");
var _jsxRuntime = require("react/jsx-runtime");
function UserProfile(_ref) {
  var _formField$props, _formField$props2, _formField$props3;
  var {
    reset,
    formField
  } = _ref;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var [triggerSetValues, setTriggerSetValues] = (0, _react.useState)(0);
  var formDetailsFlags = (0, _AnonRFS.extractFormDetailsFlags)(formField === null || formField === void 0 || (_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.basketReferenceId);
  var idTokenCookie = '';
  var email = '';
  idTokenCookie = (0, _utils.getAuthCookie)();
  ({
    email
  } = (0, _utils.decodeIdToken)(idTokenCookie));
  var {
    data,
    isSuccess,
    isFetching
  } = (0, _utils.useRestQueryRequest)(['userDetails', idTokenCookie], 'GET', "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=false"), {
    Authorization: "Bearer ".concat(idTokenCookie)
  }, {
    enabled: isLoggedIn && !formDetailsFlags.updated
  });
  var isGermanHotel = formField === null || formField === void 0 || (_formField$props2 = formField.props) === null || _formField$props2 === void 0 ? void 0 : _formField$props2.isGermanHotel;
  var isMultiRoomRedesignEnabled = formField === null || formField === void 0 || (_formField$props3 = formField.props) === null || _formField$props3 === void 0 ? void 0 : _formField$props3.isMultiRoomRedesignEnabled;
  (0, _react.useEffect)(() => {
    if (isSuccess && reset && isLoggedIn && triggerSetValues && !formDetailsFlags.updated) {
      var {
        businessUse,
        contactDetail: {
          title,
          firstName,
          lastName,
          email: _email,
          mobile,
          address: {
            companyName,
            countryCode,
            line1,
            line2,
            line3,
            line4,
            postCode,
            type
          }
        }
      } = data;
      (0, _utils.setCookie)('userData', JSON.stringify({
        firstName
      }), 24 * 60 * 60 * 1000);
      if (businessUse === true) {
        var _formField$props4, _formField$props4$upd;
        _utils.analytics.update({
          bookingReasonForStay: _api.PurposeOfStayAnalytics.BUSINESS
        });
        formField === null || formField === void 0 || (_formField$props4 = formField.props) === null || _formField$props4 === void 0 || (_formField$props4$upd = _formField$props4.updateReasonForStay) === null || _formField$props4$upd === void 0 || _formField$props4$upd.call(_formField$props4, _api.PurposeOfStay.BUSINESS);
      }
      var resetObject = {
        reasonForStay: businessUse === true ? _api.PurposeOfStay.BUSINESS : '',
        title,
        firstName,
        lastName,
        email: _email,
        phone: mobile,
        landline: '',
        manualAddressToggle: 'manualAddress',
        addressSelection: isGermanHotel && isMultiRoomRedesignEnabled ? _utils.GLOBALS.addressType.HOME : type,
        companyName: isGermanHotel && isMultiRoomRedesignEnabled ? '' : companyName,
        addressLine1: line1,
        addressLine2: line2,
        addressLine3: line3,
        addressLine4: line4,
        cityName: line4,
        postalCode: postCode,
        countryCode,
        postcodeAddress: ''
      };
      reset(resetObject);
    }
  }, [isSuccess, data, reset, triggerSetValues, isLoggedIn, formDetailsFlags.updated]);
  (0, _react.useEffect)(() => {
    setTriggerSetValues(prev => prev + 1);
  }, []);
  if (isLoggedIn && isFetching && !formDetailsFlags.updated) {
    return (0, _jsxRuntime.jsx)("span", {
      children: "Loading user profile..."
    });
  }
  return null;
}