"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CreateMyPiAccountModalContainer;
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _react2 = require("react");
var _EmailInputModal = _interopRequireDefault(require("../../common/EmailInputModal"));
var _jsxRuntime = require("react/jsx-runtime");
function CreateMyPiAccountModalContainer(_ref) {
  var {
    isModalVisible,
    onModalClose
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var [email, setEmail] = (0, _react2.useState)('');
  var [customerConsent, setCustomerConsent] = (0, _react2.useState)(false);
  var [hasEmailError, setHasEmailError] = (0, _react2.useState)(false);
  var [showNotification, setShowNotification] = (0, _react2.useState)(false);
  var [triggeringSuccessResponse, setTriggeringSuccessResponse] = (0, _react2.useState)(false);
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var modalContent = {
    baseDataTestId: 'CreateMyPiAccount',
    title: t('ccui.account.confirmEmail.title'),
    description: t('ccui.account.confirmEmail.text'),
    emailLabel: t('ccui.account.emailConfirmation'),
    inputLabel: t('ccui.account.emailInputLabel'),
    emailPlaceholder: t('ccui.email.placeholder'),
    emailErrorMsg: t('ccui.account.error.email.invalid'),
    submitBtn: t('ccui.account.createAccount'),
    successNotif: t('ccui.account.emailConfirmation.success'),
    errorNotif: t('ccui.account.emailConfirmation.error'),
    customerConsent: t('ccui.account.customerConsent')
  };
  var handleEmailChange = email => {
    return setEmail(email);
  };
  var handleCustomerConsentChange = value => {
    return setCustomerConsent(value);
  };
  var {
    mutation,
    data,
    isSuccess: isEmailTriggeringSuccess,
    isError: isEmailTriggeringError
  } = (0, _utils.useRestMutationRequest)("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/hotelaccount/emails/ccui/myPiAccount?email=").concat(email, "&language=").concat(language), 'POST', {
    Authorization: "Bearer ".concat(idTokenCookie)
  });
  var sendEmail = () => {
    mutation.mutate({
      email,
      language
    });
    setShowNotification(true);
  };
  (0, _react2.useEffect)(() => {
    if (isEmailTriggeringSuccess) {
      setTriggeringSuccessResponse(data.success);
    }
  }, [isEmailTriggeringSuccess]);
  var handleOnChange = email => {
    hasEmailError && setHasEmailError(false);
    handleEmailChange(email);
  };
  var checkIfValid = () => {
    if (!(0, _utils.isEmailValid)(email)) {
      setHasEmailError(true);
    }
  };
  var handleOnClose = () => {
    if (mutation) {
      mutation.reset();
    }
    setEmail('');
    setHasEmailError(false);
    setShowNotification(false);
    onModalClose();
    setTriggeringSuccessResponse(false);
    setCustomerConsent(false);
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "CreateMyPiAccountContainer",
    children: (0, _jsxRuntime.jsx)(_EmailInputModal.default, {
      modalContent: modalContent,
      isModalVisible: isModalVisible,
      closeOnOverlayClick: false,
      email: email,
      handleEmailChange: handleOnChange,
      handleOnSubmit: sendEmail,
      showNotification: showNotification,
      isLoading: false,
      checkIfValid: checkIfValid,
      handleOnModalClose: handleOnClose,
      hasError: hasEmailError,
      hasCustomerContent: true,
      customerConsent: customerConsent,
      handleCustomerConsentChange: handleCustomerConsentChange,
      isEmailTriggeringSuccess: triggeringSuccessResponse,
      isEmailTriggeringError: isEmailTriggeringError
    })
  });
}