"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FT_PI_WEB_PUSH_NOTIFICATIONS = exports.FT_PI_RECAPTCHA_REGISTER = exports.FT_PI_PRE_CHECK_IN = exports.FT_PI_META_CLASS_RATE_SEARCH = exports.FT_PI_HREFLANG_ATTRIBUTE = exports.FT_PI_GROUP_WEB_SSR_REST_CALL = exports.FT_PI_GROUP_BOOKING_FORM = exports.FT_PI_GDP_PAGE_DESIGN_CHANGE = exports.FT_PI_GDP_BILLING_ADDRESS = exports.FT_PI_ENABLE_PAYPAL = exports.FT_PI_ENABLE_PAYMENT_REDESIGN = exports.FT_PI_DISCOUNT_RATE = exports.FT_PI_CP_CREATE_ACCOUNT = exports.FT_PI_BREAKFAST_PROMO_CODE = exports.FT_PI_BB_DE_OPT_IN = exports.FT_PI_BB_CCUI_TRIP_ADVISOR = exports.FT_PI_BB_CCUI_COMPANY_NAME_SPECIAL_CHARACTERS = exports.FT_PI_BB_ACCOUNT_SERV_TO_SERV = exports.FT_PI_AMEND_GUEST_ADDRESS = exports.FT_PI_AMEND_DELETE_REG_CARD = exports.FT_PI_ADDITIONAL_INFORMATION = exports.FT_PI_ACCOMPANYING_GUEST_DETAILS = exports.FT_IB_ENABLED = exports.FT_CCUI_PRE_POPULATE_BILLING_ADDRESS = exports.FT_CCUI_PRE_CHECK_IN_LABEL = exports.FT_CCUI_GDP_SINGLE_BOOKING = exports.FT_CCUI_GDP_MULTI_BOOKING = exports.FT_CCUI_GDP_BILLING_ADDRESS = exports.FT_CCUI_CHANGE_PAYMENT_METHOD = exports.FT_CCUI_AMEND_GUEST_ADDRESS = exports.FT_CCUI_ADDITIONAL_INFORMATION = exports.FT_CCUI_ACCOMPANYING_GUEST_DETAILS = exports.FT_BB_GROUP_BOOKING_FORM = exports.FT_BB_ENABLE_PAYPAL = exports.FT_BB_ENABLE_PAYMENT_REDESIGN = exports.FT_BB_ACCOMPANYING_GUEST_DETAILS = void 0;
var FT_PI_ENABLE_PAYPAL = exports.FT_PI_ENABLE_PAYPAL = 'kill_switch_pi_enable-paypal';
var FT_BB_ENABLE_PAYPAL = exports.FT_BB_ENABLE_PAYPAL = 'kill_switch_bb_enable-paypal';
var FT_PI_ENABLE_PAYMENT_REDESIGN = exports.FT_PI_ENABLE_PAYMENT_REDESIGN = 'release_pi_payment_redesign';
var FT_BB_ENABLE_PAYMENT_REDESIGN = exports.FT_BB_ENABLE_PAYMENT_REDESIGN = 'release_bb_payment_redesign';
var FT_PI_AMEND_GUEST_ADDRESS = exports.FT_PI_AMEND_GUEST_ADDRESS = 'release_pi_amend_guest_address';
var FT_CCUI_AMEND_GUEST_ADDRESS = exports.FT_CCUI_AMEND_GUEST_ADDRESS = 'release_ccui_amend_guest_address';
var FT_CCUI_PRE_CHECK_IN_LABEL = exports.FT_CCUI_PRE_CHECK_IN_LABEL = 'release_ccui_pre_check_in_label';
var FT_PI_PRE_CHECK_IN = exports.FT_PI_PRE_CHECK_IN = 'release_pi_pre_check_in';
var FT_PI_GDP_BILLING_ADDRESS = exports.FT_PI_GDP_BILLING_ADDRESS = 'release_pi_gdp_billing_address';
var FT_CCUI_GDP_BILLING_ADDRESS = exports.FT_CCUI_GDP_BILLING_ADDRESS = 'release_ccui_indigo_gdp-billing-address';
var FT_CCUI_GDP_MULTI_BOOKING = exports.FT_CCUI_GDP_MULTI_BOOKING = 'release_ccui_indigo_gdp-multi-booking';
var FT_CCUI_GDP_SINGLE_BOOKING = exports.FT_CCUI_GDP_SINGLE_BOOKING = 'release_ccui_indigo_gdp-single-booking';
var FT_PI_GDP_PAGE_DESIGN_CHANGE = exports.FT_PI_GDP_PAGE_DESIGN_CHANGE = 'release_pi_gdp_page_design_change';
var FT_PI_ADDITIONAL_INFORMATION = exports.FT_PI_ADDITIONAL_INFORMATION = 'release_pi_additional_information';
var FT_PI_AMEND_DELETE_REG_CARD = exports.FT_PI_AMEND_DELETE_REG_CARD = 'release_pi_amend_delete_reg_card';
var FT_CCUI_ADDITIONAL_INFORMATION = exports.FT_CCUI_ADDITIONAL_INFORMATION = 'release_ccui_additional_information';
var FT_IB_ENABLED = exports.FT_IB_ENABLED = 'release_ib_enabled';
var FT_PI_RECAPTCHA_REGISTER = exports.FT_PI_RECAPTCHA_REGISTER = 'kill_switch_pi_recaptcha_register_page';
var FT_CCUI_PRE_POPULATE_BILLING_ADDRESS = exports.FT_CCUI_PRE_POPULATE_BILLING_ADDRESS = 'release-pre-populate-different-billing-address-ccui';
var FT_PI_BB_CCUI_TRIP_ADVISOR = exports.FT_PI_BB_CCUI_TRIP_ADVISOR = 'release_pi_bb_ccui_white_trip_advisor_review';
var FT_PI_GROUP_BOOKING_FORM = exports.FT_PI_GROUP_BOOKING_FORM = 'release_pi_group_booking_form';
var FT_BB_GROUP_BOOKING_FORM = exports.FT_BB_GROUP_BOOKING_FORM = 'release_bb_group_booking_form';
var FT_PI_HREFLANG_ATTRIBUTE = exports.FT_PI_HREFLANG_ATTRIBUTE = 'release_pi_hreflang_attribute';
var FT_PI_WEB_PUSH_NOTIFICATIONS = exports.FT_PI_WEB_PUSH_NOTIFICATIONS = 'release_pi_web_push_notifications';
var FT_PI_BREAKFAST_PROMO_CODE = exports.FT_PI_BREAKFAST_PROMO_CODE = 'release_pi_breakfast_promo_code';
var FT_PI_GROUP_WEB_SSR_REST_CALL = exports.FT_PI_GROUP_WEB_SSR_REST_CALL = 'release_pi_group_booking_form_ssr_rest_call';
var FT_CCUI_CHANGE_PAYMENT_METHOD = exports.FT_CCUI_CHANGE_PAYMENT_METHOD = 'release_ccui_change_payment_method';
var FT_PI_META_CLASS_RATE_SEARCH = exports.FT_PI_META_CLASS_RATE_SEARCH = 'kill_switch_pi_meta_search';
var FT_PI_CP_CREATE_ACCOUNT = exports.FT_PI_CP_CREATE_ACCOUNT = 'release_pi_cp_create_account';
var FT_CCUI_ACCOMPANYING_GUEST_DETAILS = exports.FT_CCUI_ACCOMPANYING_GUEST_DETAILS = 'release_ccui_accompanying_guest_details';
var FT_PI_BB_DE_OPT_IN = exports.FT_PI_BB_DE_OPT_IN = 'release_pi_bb_de_optin';
var FT_PI_BB_ACCOUNT_SERV_TO_SERV = exports.FT_PI_BB_ACCOUNT_SERV_TO_SERV = 'release_pi_bb_account_serv_2_serv';
var FT_PI_DISCOUNT_RATE = exports.FT_PI_DISCOUNT_RATE = 'release_pi_discount_rate';
var FT_BB_ACCOMPANYING_GUEST_DETAILS = exports.FT_BB_ACCOMPANYING_GUEST_DETAILS = 'release_bb_accompanying_guest_details';
var FT_PI_BB_CCUI_COMPANY_NAME_SPECIAL_CHARACTERS = exports.FT_PI_BB_CCUI_COMPANY_NAME_SPECIAL_CHARACTERS = 'release_pi_bb_ccui_company_name_special_characters';
var FT_PI_ACCOMPANYING_GUEST_DETAILS = exports.FT_PI_ACCOMPANYING_GUEST_DETAILS = 'release_pi_accompanying_guest_details';