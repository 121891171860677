"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ACCESS_TOKEN_COOKIE", {
  enumerable: true,
  get: function get() {
    return _getters.ACCESS_TOKEN_COOKIE;
  }
});
Object.defineProperty(exports, "AgentMemoProvider", {
  enumerable: true,
  get: function get() {
    return _store.AgentMemoProvider;
  }
});
Object.defineProperty(exports, "AnalyticsProvider", {
  enumerable: true,
  get: function get() {
    return _services.AnalyticsProvider;
  }
});
Object.defineProperty(exports, "AnalyticsProviderCCUI", {
  enumerable: true,
  get: function get() {
    return _services.AnalyticsProviderCCUI;
  }
});
Object.defineProperty(exports, "AppDataProvider", {
  enumerable: true,
  get: function get() {
    return _store.AppDataProvider;
  }
});
Object.defineProperty(exports, "CDH_COMPANY_ID", {
  enumerable: true,
  get: function get() {
    return _getters.CDH_COMPANY_ID;
  }
});
Object.defineProperty(exports, "CDH_EMPLOYEE_ID", {
  enumerable: true,
  get: function get() {
    return _getters.CDH_EMPLOYEE_ID;
  }
});
Object.defineProperty(exports, "EMAIL_COOKIE", {
  enumerable: true,
  get: function get() {
    return _getters.EMAIL_COOKIE;
  }
});
Object.defineProperty(exports, "FeatureToggleContext", {
  enumerable: true,
  get: function get() {
    return _store.FeatureToggleContext;
  }
});
Object.defineProperty(exports, "FeatureToggleContextProvider", {
  enumerable: true,
  get: function get() {
    return _store.FeatureToggleContextProvider;
  }
});
Object.defineProperty(exports, "GLOBALS", {
  enumerable: true,
  get: function get() {
    return _globalConstants.GLOBALS;
  }
});
Object.defineProperty(exports, "ID_TOKEN_COOKIE", {
  enumerable: true,
  get: function get() {
    return _getters.ID_TOKEN_COOKIE;
  }
});
Object.defineProperty(exports, "MANUAL_ADDRESS", {
  enumerable: true,
  get: function get() {
    return _globalConstants.MANUAL_ADDRESS;
  }
});
Object.defineProperty(exports, "MAX_AGE", {
  enumerable: true,
  get: function get() {
    return _getters.MAX_AGE;
  }
});
Object.defineProperty(exports, "OPERA_COMPANY_ID", {
  enumerable: true,
  get: function get() {
    return _getters.OPERA_COMPANY_ID;
  }
});
Object.defineProperty(exports, "QueriesLogger", {
  enumerable: true,
  get: function get() {
    return _queriesLogger.default;
  }
});
Object.defineProperty(exports, "REGISTER_BASKET_REF_COOKIE", {
  enumerable: true,
  get: function get() {
    return _globalConstants.REGISTER_BASKET_REF_COOKIE;
  }
});
Object.defineProperty(exports, "UserContextProvider", {
  enumerable: true,
  get: function get() {
    return _store.UserContextProvider;
  }
});
Object.defineProperty(exports, "UserDataContext", {
  enumerable: true,
  get: function get() {
    return _store.UserDataContext;
  }
});
Object.defineProperty(exports, "addOrRemoveAllRoomsExtras", {
  enumerable: true,
  get: function get() {
    return _selectors.addOrRemoveAllRoomsExtras;
  }
});
Object.defineProperty(exports, "addOrRemoveExtrasRoom", {
  enumerable: true,
  get: function get() {
    return _selectors.addOrRemoveExtrasRoom;
  }
});
Object.defineProperty(exports, "addReservationNumber", {
  enumerable: true,
  get: function get() {
    return _selectors.addReservationNumber;
  }
});
Object.defineProperty(exports, "adultsMealsSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.adultsMealsSelector;
  }
});
Object.defineProperty(exports, "akamaiImageLoader", {
  enumerable: true,
  get: function get() {
    return _helpers.akamaiImageLoader;
  }
});
Object.defineProperty(exports, "analytics", {
  enumerable: true,
  get: function get() {
    return _services.analytics;
  }
});
Object.defineProperty(exports, "analyticsConfirmation", {
  enumerable: true,
  get: function get() {
    return _services.analyticsConfirmation;
  }
});
Object.defineProperty(exports, "analyticsConfirmationPageName", {
  enumerable: true,
  get: function get() {
    return _services.analyticsConfirmationPageName;
  }
});
Object.defineProperty(exports, "analyticsTrackings", {
  enumerable: true,
  get: function get() {
    return _services.analyticsTrackings;
  }
});
Object.defineProperty(exports, "autocompleteMeals", {
  enumerable: true,
  get: function get() {
    return _selectors.autocompleteMeals;
  }
});
Object.defineProperty(exports, "axiosRequest", {
  enumerable: true,
  get: function get() {
    return _hooks.axiosRequest;
  }
});
Object.defineProperty(exports, "bknReservationsMock", {
  enumerable: true,
  get: function get() {
    return _mockData.bknReservationsMock;
  }
});
Object.defineProperty(exports, "boldMatchCharacters", {
  enumerable: true,
  get: function get() {
    return _selectors.boldMatchCharacters;
  }
});
Object.defineProperty(exports, "bookingGuestCount", {
  enumerable: true,
  get: function get() {
    return _selectors.bookingGuestCount;
  }
});
Object.defineProperty(exports, "calculateTotalCostRoomSelection", {
  enumerable: true,
  get: function get() {
    return _selectors.calculateTotalCostRoomSelection;
  }
});
Object.defineProperty(exports, "checkDateFormatter", {
  enumerable: true,
  get: function get() {
    return _formatters.checkDateFormatter;
  }
});
Object.defineProperty(exports, "checkIfExistPreselection", {
  enumerable: true,
  get: function get() {
    return _selectors.checkIfExistPreselection;
  }
});
Object.defineProperty(exports, "childrenMealsSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.childrenMealsSelector;
  }
});
Object.defineProperty(exports, "cleanupFindBookingToken", {
  enumerable: true,
  get: function get() {
    return _getters.cleanupFindBookingToken;
  }
});
Object.defineProperty(exports, "clientLogger", {
  enumerable: true,
  get: function get() {
    return _clientLogger.default;
  }
});
Object.defineProperty(exports, "createOptionsAdultsChildrenDropdown", {
  enumerable: true,
  get: function get() {
    return _helpers.createOptionsAdultsChildrenDropdown;
  }
});
Object.defineProperty(exports, "createReservationDetails", {
  enumerable: true,
  get: function get() {
    return _formatters.createReservationDetails;
  }
});
Object.defineProperty(exports, "decodeFromBase64", {
  enumerable: true,
  get: function get() {
    return _helpers.decodeFromBase64;
  }
});
Object.defineProperty(exports, "decodeIdToken", {
  enumerable: true,
  get: function get() {
    return _getters.decodeIdToken;
  }
});
Object.defineProperty(exports, "deleteCookie", {
  enumerable: true,
  get: function get() {
    return _helpers.deleteCookie;
  }
});
Object.defineProperty(exports, "displayMethodType", {
  enumerable: true,
  get: function get() {
    return _helpers.displayMethodType;
  }
});
Object.defineProperty(exports, "displayPaymentHelpText", {
  enumerable: true,
  get: function get() {
    return _helpers.displayPaymentHelpText;
  }
});
Object.defineProperty(exports, "displayStorageSubstitutionLabels", {
  enumerable: true,
  get: function get() {
    return _getters.displayStorageSubstitutionLabels;
  }
});
Object.defineProperty(exports, "encodeToBase64", {
  enumerable: true,
  get: function get() {
    return _helpers.encodeToBase64;
  }
});
Object.defineProperty(exports, "enhanceCheckoutStepList", {
  enumerable: true,
  get: function get() {
    return _selectors.enhanceCheckoutStepList;
  }
});
Object.defineProperty(exports, "enhanceMeal", {
  enumerable: true,
  get: function get() {
    return _selectors.enhanceMeal;
  }
});
Object.defineProperty(exports, "enhancePrivacyPolicyMoreInfoItem", {
  enumerable: true,
  get: function get() {
    return _selectors.enhancePrivacyPolicyMoreInfoItem;
  }
});
Object.defineProperty(exports, "enhanceSelectedMeals", {
  enumerable: true,
  get: function get() {
    return _selectors.enhanceSelectedMeals;
  }
});
Object.defineProperty(exports, "extrasNamingCheck", {
  enumerable: true,
  get: function get() {
    return _getters.extrasNamingCheck;
  }
});
Object.defineProperty(exports, "extrasPackagesAnalyticsMap", {
  enumerable: true,
  get: function get() {
    return _services.extrasPackagesAnalyticsMap;
  }
});
Object.defineProperty(exports, "extrasPackagesMapperSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.extrasPackagesMapperSelector;
  }
});
Object.defineProperty(exports, "filterPackagesByAncillariesCloseOut", {
  enumerable: true,
  get: function get() {
    return _selectors.filterPackagesByAncillariesCloseOut;
  }
});
Object.defineProperty(exports, "filterWiFiForPlusRooms", {
  enumerable: true,
  get: function get() {
    return _getters.filterWiFiForPlusRooms;
  }
});
Object.defineProperty(exports, "formatAccompanyingGuest", {
  enumerable: true,
  get: function get() {
    return _formatters.formatAccompanyingGuest;
  }
});
Object.defineProperty(exports, "formatAssetsUrl", {
  enumerable: true,
  get: function get() {
    return _formatters.formatAssetsUrl;
  }
});
Object.defineProperty(exports, "formatBillingAddress", {
  enumerable: true,
  get: function get() {
    return _formatters.formatBillingAddress;
  }
});
Object.defineProperty(exports, "formatCurrency", {
  enumerable: true,
  get: function get() {
    return _formatters.formatCurrency;
  }
});
Object.defineProperty(exports, "formatDataTestId", {
  enumerable: true,
  get: function get() {
    return _formatters.formatDataTestId;
  }
});
Object.defineProperty(exports, "formatDate", {
  enumerable: true,
  get: function get() {
    return _formatters.formatDate;
  }
});
Object.defineProperty(exports, "formatFindBookingToken", {
  enumerable: true,
  get: function get() {
    return _formatters.formatFindBookingToken;
  }
});
Object.defineProperty(exports, "formatForMobile", {
  enumerable: true,
  get: function get() {
    return _formatters.formatForMobile;
  }
});
Object.defineProperty(exports, "formatGuestTitleOptions", {
  enumerable: true,
  get: function get() {
    return _formatters.formatGuestTitleOptions;
  }
});
Object.defineProperty(exports, "formatGuests", {
  enumerable: true,
  get: function get() {
    return _formatters.formatGuests;
  }
});
Object.defineProperty(exports, "formatImportantNotes", {
  enumerable: true,
  get: function get() {
    return _selectors.formatImportantNotes;
  }
});
Object.defineProperty(exports, "formatInnerHTMLAssetUrls", {
  enumerable: true,
  get: function get() {
    return _formatters.formatInnerHTMLAssetUrls;
  }
});
Object.defineProperty(exports, "formatNextLocaleLink", {
  enumerable: true,
  get: function get() {
    return _formatters.formatNextLocaleLink;
  }
});
Object.defineProperty(exports, "formatPrice", {
  enumerable: true,
  get: function get() {
    return _formatters.formatPrice;
  }
});
Object.defineProperty(exports, "formatPriceWithDecimal", {
  enumerable: true,
  get: function get() {
    return _formatters.formatPriceWithDecimal;
  }
});
Object.defineProperty(exports, "formatRequiredString", {
  enumerable: true,
  get: function get() {
    return _formatters.formatRequiredString;
  }
});
Object.defineProperty(exports, "formatTextWithSpace", {
  enumerable: true,
  get: function get() {
    return _formatters.formatTextWithSpace;
  }
});
Object.defineProperty(exports, "formatUrlTermsConditions", {
  enumerable: true,
  get: function get() {
    return _formatters.formatUrlTermsConditions;
  }
});
Object.defineProperty(exports, "freeBreakfastMaxAllowance", {
  enumerable: true,
  get: function get() {
    return _selectors.freeBreakfastMaxAllowance;
  }
});
Object.defineProperty(exports, "freeBreakfastMaxSelectedAllowance", {
  enumerable: true,
  get: function get() {
    return _selectors.freeBreakfastMaxSelectedAllowance;
  }
});
Object.defineProperty(exports, "generateSuggestionList", {
  enumerable: true,
  get: function get() {
    return _selectors.generateSuggestionList;
  }
});
Object.defineProperty(exports, "getA2CBusinessAllowances", {
  enumerable: true,
  get: function get() {
    return _getters.getA2CBusinessAllowances;
  }
});
Object.defineProperty(exports, "getAccessTokenCookie", {
  enumerable: true,
  get: function get() {
    return _getters.getAccessTokenCookie;
  }
});
Object.defineProperty(exports, "getAdultMealDescription", {
  enumerable: true,
  get: function get() {
    return _getters.getAdultMealDescription;
  }
});
Object.defineProperty(exports, "getAdultsChildrenOptions", {
  enumerable: true,
  get: function get() {
    return _helpers.getAdultsChildrenOptions;
  }
});
Object.defineProperty(exports, "getAllRoomTypesFromRates", {
  enumerable: true,
  get: function get() {
    return _getters.getAllRoomTypesFromRates;
  }
});
Object.defineProperty(exports, "getAmendSectionTranslations", {
  enumerable: true,
  get: function get() {
    return _getters.getAmendSectionTranslations;
  }
});
Object.defineProperty(exports, "getAuthCookie", {
  enumerable: true,
  get: function get() {
    return _getters.getAuthCookie;
  }
});
Object.defineProperty(exports, "getAvailabilityParamsFromUrl", {
  enumerable: true,
  get: function get() {
    return _getters.getAvailabilityParamsFromUrl;
  }
});
Object.defineProperty(exports, "getAvailabilityRoomClassIndexes", {
  enumerable: true,
  get: function get() {
    return _getters.getAvailabilityRoomClassIndexes;
  }
});
Object.defineProperty(exports, "getAvailableRoomTypes", {
  enumerable: true,
  get: function get() {
    return _getters.getAvailableRoomTypes;
  }
});
Object.defineProperty(exports, "getBillingAddress", {
  enumerable: true,
  get: function get() {
    return _getters.getBillingAddress;
  }
});
Object.defineProperty(exports, "getBookingDetailsForCard", {
  enumerable: true,
  get: function get() {
    return _getters.getBookingDetailsForCard;
  }
});
Object.defineProperty(exports, "getBookingSummaryData", {
  enumerable: true,
  get: function get() {
    return _getters.getBookingSummaryData;
  }
});
Object.defineProperty(exports, "getCardEnding", {
  enumerable: true,
  get: function get() {
    return _getters.getCardEnding;
  }
});
Object.defineProperty(exports, "getCentrallyStoredCardBillingAddress", {
  enumerable: true,
  get: function get() {
    return _getters.getCentrallyStoredCardBillingAddress;
  }
});
Object.defineProperty(exports, "getChildrenMealDescription", {
  enumerable: true,
  get: function get() {
    return _getters.getChildrenMealDescription;
  }
});
Object.defineProperty(exports, "getCityTaxMessages", {
  enumerable: true,
  get: function get() {
    return _getters.getCityTaxMessages;
  }
});
Object.defineProperty(exports, "getClientDefaultSessionTracing", {
  enumerable: true,
  get: function get() {
    return _logger.getClientDefaultSessionTracing;
  }
});
Object.defineProperty(exports, "getCookie", {
  enumerable: true,
  get: function get() {
    return _helpers.getCookie;
  }
});
Object.defineProperty(exports, "getCorporateDiscountRatePlanCode", {
  enumerable: true,
  get: function get() {
    return _hooks.getCorporateDiscountRatePlanCode;
  }
});
Object.defineProperty(exports, "getCurrentReservationStorageData", {
  enumerable: true,
  get: function get() {
    return _selectors.getCurrentReservationStorageData;
  }
});
Object.defineProperty(exports, "getDefaultDataFromBooking", {
  enumerable: true,
  get: function get() {
    return _getters.getDefaultDataFromBooking;
  }
});
Object.defineProperty(exports, "getDefaultRooms", {
  enumerable: true,
  get: function get() {
    return _getters.getDefaultRooms;
  }
});
Object.defineProperty(exports, "getDefaultSessionTracing", {
  enumerable: true,
  get: function get() {
    return _logger.getDefaultSessionTracing;
  }
});
Object.defineProperty(exports, "getDistanceUnitBasedOnLocale", {
  enumerable: true,
  get: function get() {
    return _getters.getDistanceUnitBasedOnLocale;
  }
});
Object.defineProperty(exports, "getDistanceUnitBasedOnLocaleSb", {
  enumerable: true,
  get: function get() {
    return _getters.getDistanceUnitBasedOnLocaleSb;
  }
});
Object.defineProperty(exports, "getDonationForBooking", {
  enumerable: true,
  get: function get() {
    return _helpers.getDonationForBooking;
  }
});
Object.defineProperty(exports, "getExtrasPackagePrice", {
  enumerable: true,
  get: function get() {
    return _getters.getExtrasPackagePrice;
  }
});
Object.defineProperty(exports, "getFindBookingToken", {
  enumerable: true,
  get: function get() {
    return _getters.getFindBookingToken;
  }
});
Object.defineProperty(exports, "getGQLClient", {
  enumerable: true,
  get: function get() {
    return _client.getGQLClient;
  }
});
Object.defineProperty(exports, "getHotelAvailabilityQueryKey", {
  enumerable: true,
  get: function get() {
    return _getters.getHotelAvailabilityQueryKey;
  }
});
Object.defineProperty(exports, "getI18nLabels", {
  enumerable: true,
  get: function get() {
    return _getters.getI18nLabels;
  }
});
Object.defineProperty(exports, "getIDVPassedStatus", {
  enumerable: true,
  get: function get() {
    return _getters.getIDVPassedStatus;
  }
});
Object.defineProperty(exports, "getIsBBCardDetailsDisplayed", {
  enumerable: true,
  get: function get() {
    return _getters.getIsBBCardDetailsDisplayed;
  }
});
Object.defineProperty(exports, "getIsBillingAddressDisplayed", {
  enumerable: true,
  get: function get() {
    return _getters.getIsBillingAddressDisplayed;
  }
});
Object.defineProperty(exports, "getIsDonationInfoBoxDisplayed", {
  enumerable: true,
  get: function get() {
    return _getters.getIsDonationInfoBoxDisplayed;
  }
});
Object.defineProperty(exports, "getIsDonationsDisplayed", {
  enumerable: true,
  get: function get() {
    return _getters.getIsDonationsDisplayed;
  }
});
Object.defineProperty(exports, "getListOfEmployees", {
  enumerable: true,
  get: function get() {
    return _getters.getListOfEmployees;
  }
});
Object.defineProperty(exports, "getLocalStorageMock", {
  enumerable: true,
  get: function get() {
    return _getters.getLocalStorageMock;
  }
});
Object.defineProperty(exports, "getLoggedInUserInfo", {
  enumerable: true,
  get: function get() {
    return _getters.getLoggedInUserInfo;
  }
});
Object.defineProperty(exports, "getLogger", {
  enumerable: true,
  get: function get() {
    return _logger.getLogger;
  }
});
Object.defineProperty(exports, "getMappedRooms", {
  enumerable: true,
  get: function get() {
    return _helpers.getMappedRooms;
  }
});
Object.defineProperty(exports, "getMaxValueFromHDPRoomTypes", {
  enumerable: true,
  get: function get() {
    return _getters.getMaxValueFromHDPRoomTypes;
  }
});
Object.defineProperty(exports, "getMaxValueFromRoomStays", {
  enumerable: true,
  get: function get() {
    return _getters.getMaxValueFromRoomStays;
  }
});
Object.defineProperty(exports, "getMaxValueFromRoomStaysBC", {
  enumerable: true,
  get: function get() {
    return _getters.getMaxValueFromRoomStaysBC;
  }
});
Object.defineProperty(exports, "getMealPrice", {
  enumerable: true,
  get: function get() {
    return _getters.getMealPrice;
  }
});
Object.defineProperty(exports, "getNightsNumber", {
  enumerable: true,
  get: function get() {
    return _getters.getNightsNumber;
  }
});
Object.defineProperty(exports, "getNoOfDaysInYear", {
  enumerable: true,
  get: function get() {
    return _getters.getNoOfDaysInYear;
  }
});
Object.defineProperty(exports, "getPathForLocale", {
  enumerable: true,
  get: function get() {
    return _formatters.getPathForLocale;
  }
});
Object.defineProperty(exports, "getPaymentError", {
  enumerable: true,
  get: function get() {
    return _getters.getPaymentError;
  }
});
Object.defineProperty(exports, "getPaypalDeviceData", {
  enumerable: true,
  get: function get() {
    return _getters.getPaypalDeviceData;
  }
});
Object.defineProperty(exports, "getPaypalOptionsParams", {
  enumerable: true,
  get: function get() {
    return _getters.getPaypalOptionsParams;
  }
});
Object.defineProperty(exports, "getPlace", {
  enumerable: true,
  get: function get() {
    return _getters.getPlace;
  }
});
Object.defineProperty(exports, "getPlaceholderString", {
  enumerable: true,
  get: function get() {
    return _getters.getPlaceholderString;
  }
});
Object.defineProperty(exports, "getPriceValueWithDecimal", {
  enumerable: true,
  get: function get() {
    return _formatters.getPriceValueWithDecimal;
  }
});
Object.defineProperty(exports, "getRateClassification", {
  enumerable: true,
  get: function get() {
    return _getters.getRateClassification;
  }
});
Object.defineProperty(exports, "getRatePlanCode", {
  enumerable: true,
  get: function get() {
    return _hooks.getRatePlanCode;
  }
});
Object.defineProperty(exports, "getRoomClassByRoomClassCode", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomClassByRoomClassCode;
  }
});
Object.defineProperty(exports, "getRoomDetailsForBartCard", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomDetailsForBartCard;
  }
});
Object.defineProperty(exports, "getRoomExtrasTotal", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomExtrasTotal;
  }
});
Object.defineProperty(exports, "getRoomGroups", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomGroups;
  }
});
Object.defineProperty(exports, "getRoomRatesThatMatchRoomClassifications", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomRatesThatMatchRoomClassifications;
  }
});
Object.defineProperty(exports, "getRoomTotalprice", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomTotalprice;
  }
});
Object.defineProperty(exports, "getRoomTypeLabelsBySourceSystem", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomTypeLabelsBySourceSystem;
  }
});
Object.defineProperty(exports, "getRoomTypeOptions", {
  enumerable: true,
  get: function get() {
    return _helpers.getRoomTypeOptions;
  }
});
Object.defineProperty(exports, "getRoomTypesFromQuery", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomTypesFromQuery;
  }
});
Object.defineProperty(exports, "getRoomsPlaceholder", {
  enumerable: true,
  get: function get() {
    return _getters.getRoomsPlaceholder;
  }
});
Object.defineProperty(exports, "getSecureTwoURL", {
  enumerable: true,
  get: function get() {
    return _getters.getSecureTwoURL;
  }
});
Object.defineProperty(exports, "getSelectedDonationPackage", {
  enumerable: true,
  get: function get() {
    return _getters.getSelectedDonationPackage;
  }
});
Object.defineProperty(exports, "getSelectedPMSRoomTypesAndSpecialRequests", {
  enumerable: true,
  get: function get() {
    return _getters.getSelectedPMSRoomTypesAndSpecialRequests;
  }
});
Object.defineProperty(exports, "getServerSideCustomLocale", {
  enumerable: true,
  get: function get() {
    return _getters.getServerSideCustomLocale;
  }
});
Object.defineProperty(exports, "getSessionStorageValuesForBookings", {
  enumerable: true,
  get: function get() {
    return _getters.getSessionStorageValuesForBookings;
  }
});
Object.defineProperty(exports, "getSortedCountries", {
  enumerable: true,
  get: function get() {
    return _getters.getSortedCountries;
  }
});
Object.defineProperty(exports, "getSortedCountriesByCurrentLang", {
  enumerable: true,
  get: function get() {
    return _getters.getSortedCountriesByCurrentLang;
  }
});
Object.defineProperty(exports, "getSortedCountriesByCurrentLangCcui", {
  enumerable: true,
  get: function get() {
    return _getters.getSortedCountriesByCurrentLangCcui;
  }
});
Object.defineProperty(exports, "getTitleDropdownValues", {
  enumerable: true,
  get: function get() {
    return _getters.getTitleDropdownValues;
  }
});
Object.defineProperty(exports, "getTotalCost", {
  enumerable: true,
  get: function get() {
    return _helpers.getTotalCost;
  }
});
Object.defineProperty(exports, "getTotalPeople", {
  enumerable: true,
  get: function get() {
    return _getters.getTotalPeople;
  }
});
Object.defineProperty(exports, "getTwinRoomSelectionPrices", {
  enumerable: true,
  get: function get() {
    return _getters.getTwinRoomSelectionPrices;
  }
});
Object.defineProperty(exports, "getUniqueRoomProperties", {
  enumerable: true,
  get: function get() {
    return _selectors.getUniqueRoomProperties;
  }
});
Object.defineProperty(exports, "getUnleashToggles", {
  enumerable: true,
  get: function get() {
    return _helpers.getUnleashToggles;
  }
});
Object.defineProperty(exports, "getYearsAgoFromNow", {
  enumerable: true,
  get: function get() {
    return _formatters.getYearsAgoFromNow;
  }
});
Object.defineProperty(exports, "graphQLRequest", {
  enumerable: true,
  get: function get() {
    return _hooks.graphQLRequest;
  }
});
Object.defineProperty(exports, "handleManageBookingParamsCCUI", {
  enumerable: true,
  get: function get() {
    return _helpers.handleManageBookingParamsCCUI;
  }
});
Object.defineProperty(exports, "handleRefHeightChange", {
  enumerable: true,
  get: function get() {
    return _helpers.handleRefHeightChange;
  }
});
Object.defineProperty(exports, "hasValidCharacters", {
  enumerable: true,
  get: function get() {
    return _validators.hasValidCharacters;
  }
});
Object.defineProperty(exports, "hashString", {
  enumerable: true,
  get: function get() {
    return _helpers.hashString;
  }
});
Object.defineProperty(exports, "hotelInformationSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.hotelInformationSelector;
  }
});
Object.defineProperty(exports, "initConfirmationAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.initConfirmationAnalytics;
  }
});
Object.defineProperty(exports, "instrument", {
  enumerable: true,
  get: function get() {
    return _logger.instrument;
  }
});
Object.defineProperty(exports, "instrumentQueryClient", {
  enumerable: true,
  get: function get() {
    return _logger.instrumentQueryClient;
  }
});
Object.defineProperty(exports, "isAlphabetic", {
  enumerable: true,
  get: function get() {
    return _validators.isAlphabetic;
  }
});
Object.defineProperty(exports, "isApplePayConfigured", {
  enumerable: true,
  get: function get() {
    return _helpers.isApplePayConfigured;
  }
});
Object.defineProperty(exports, "isArrivalDateWithinSetHours", {
  enumerable: true,
  get: function get() {
    return _selectors.isArrivalDateWithinSetHours;
  }
});
Object.defineProperty(exports, "isCityTaxAvailable", {
  enumerable: true,
  get: function get() {
    return _selectors.isCityTaxAvailable;
  }
});
Object.defineProperty(exports, "isDataCollectionMsgAndFormVisible", {
  enumerable: true,
  get: function get() {
    return _selectors.isDataCollectionMsgAndFormVisible;
  }
});
Object.defineProperty(exports, "isDateValid", {
  enumerable: true,
  get: function get() {
    return _validators.isDateValid;
  }
});
Object.defineProperty(exports, "isEmailValid", {
  enumerable: true,
  get: function get() {
    return _validators.isEmailValid;
  }
});
Object.defineProperty(exports, "isFaqValidForRender", {
  enumerable: true,
  get: function get() {
    return _getters.isFaqValidForRender;
  }
});
Object.defineProperty(exports, "isGuestDetailsPageAllowed", {
  enumerable: true,
  get: function get() {
    return _selectors.isGuestDetailsPageAllowed;
  }
});
Object.defineProperty(exports, "isHotelOpeningSoon", {
  enumerable: true,
  get: function get() {
    return _getters.isHotelOpeningSoon;
  }
});
Object.defineProperty(exports, "isIVMEnabled", {
  enumerable: true,
  get: function get() {
    return _helpers.isIVMEnabled;
  }
});
Object.defineProperty(exports, "isJsonValid", {
  enumerable: true,
  get: function get() {
    return _validators.isJsonValid;
  }
});
Object.defineProperty(exports, "isNonEmptyString", {
  enumerable: true,
  get: function get() {
    return _validators.isNonEmptyString;
  }
});
Object.defineProperty(exports, "isNumber", {
  enumerable: true,
  get: function get() {
    return _validators.isNumber;
  }
});
Object.defineProperty(exports, "isObjectNotEmpty", {
  enumerable: true,
  get: function get() {
    return _helpers.isObjectNotEmpty;
  }
});
Object.defineProperty(exports, "isSameDate", {
  enumerable: true,
  get: function get() {
    return _validators.isSameDate;
  }
});
Object.defineProperty(exports, "isStringValid", {
  enumerable: true,
  get: function get() {
    return _validators.isStringValid;
  }
});
Object.defineProperty(exports, "isTheSameGuest", {
  enumerable: true,
  get: function get() {
    return _selectors.isTheSameGuest;
  }
});
Object.defineProperty(exports, "isUrl", {
  enumerable: true,
  get: function get() {
    return _validators.isUrl;
  }
});
Object.defineProperty(exports, "langFilter", {
  enumerable: true,
  get: function get() {
    return _formatters.langFilter;
  }
});
Object.defineProperty(exports, "logger", {
  enumerable: true,
  get: function get() {
    return _logger.logger;
  }
});
Object.defineProperty(exports, "logicalAndOperator", {
  enumerable: true,
  get: function get() {
    return _validators.logicalAndOperator;
  }
});
Object.defineProperty(exports, "logicalOrOperator", {
  enumerable: true,
  get: function get() {
    return _validators.logicalOrOperator;
  }
});
Object.defineProperty(exports, "logout", {
  enumerable: true,
  get: function get() {
    return _getters.logout;
  }
});
Object.defineProperty(exports, "mappingBookingStatus", {
  enumerable: true,
  get: function get() {
    return _bookingStatus.mappingBookingStatus;
  }
});
Object.defineProperty(exports, "matchSilentSubstitutions", {
  enumerable: true,
  get: function get() {
    return _formatters.matchSilentSubstitutions;
  }
});
Object.defineProperty(exports, "mealsMapperSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.mealsMapperSelector;
  }
});
Object.defineProperty(exports, "menusSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.menusSelector;
  }
});
Object.defineProperty(exports, "numberOfSelectionsPerRoomSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.numberOfSelectionsPerRoomSelector;
  }
});
Object.defineProperty(exports, "removeHtmlTags", {
  enumerable: true,
  get: function get() {
    return _formatters.removeHtmlTags;
  }
});
Object.defineProperty(exports, "renderSanitizedHtml", {
  enumerable: true,
  get: function get() {
    return _formatters.renderSanitizedHtml;
  }
});
Object.defineProperty(exports, "replaceWithEmptyString", {
  enumerable: true,
  get: function get() {
    return _formatters.replaceWithEmptyString;
  }
});
Object.defineProperty(exports, "roomDefaultValueIfError", {
  enumerable: true,
  get: function get() {
    return _server.roomDefaultValueIfError;
  }
});
Object.defineProperty(exports, "roomInformationSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.roomInformationSelector;
  }
});
Object.defineProperty(exports, "roomPackageSelection", {
  enumerable: true,
  get: function get() {
    return _selectors.roomPackageSelection;
  }
});
Object.defineProperty(exports, "securityNoticeMoreInfoDataSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.securityNoticeMoreInfoDataSelector;
  }
});
Object.defineProperty(exports, "selectedMealsPerRoomSelector", {
  enumerable: true,
  get: function get() {
    return _selectors.selectedMealsPerRoomSelector;
  }
});
Object.defineProperty(exports, "setAnalyticsPageNameAndType", {
  enumerable: true,
  get: function get() {
    return _services.setAnalyticsPageNameAndType;
  }
});
Object.defineProperty(exports, "setAnalyticsUser", {
  enumerable: true,
  get: function get() {
    return _services.setAnalyticsUser;
  }
});
Object.defineProperty(exports, "setBasketCookie", {
  enumerable: true,
  get: function get() {
    return _helpers.setBasketCookie;
  }
});
Object.defineProperty(exports, "setBasketDetailsCookie", {
  enumerable: true,
  get: function get() {
    return _helpers.setBasketDetailsCookie;
  }
});
Object.defineProperty(exports, "setBookingCookie", {
  enumerable: true,
  get: function get() {
    return _helpers.setBookingCookie;
  }
});
Object.defineProperty(exports, "setBusinessAllowancesSections", {
  enumerable: true,
  get: function get() {
    return _getters.setBusinessAllowancesSections;
  }
});
Object.defineProperty(exports, "setCookie", {
  enumerable: true,
  get: function get() {
    return _helpers.setCookie;
  }
});
Object.defineProperty(exports, "setCookieWithDefaultDomain", {
  enumerable: true,
  get: function get() {
    return _helpers.setCookieWithDefaultDomain;
  }
});
Object.defineProperty(exports, "shouldDisplayAutocompleteNotification", {
  enumerable: true,
  get: function get() {
    return _selectors.shouldDisplayAutocompleteNotification;
  }
});
Object.defineProperty(exports, "sortImagesByOrder", {
  enumerable: true,
  get: function get() {
    return _helpers.sortImagesByOrder;
  }
});
Object.defineProperty(exports, "sortMealsByOrderOrName", {
  enumerable: true,
  get: function get() {
    return _selectors.sortMealsByOrderOrName;
  }
});
Object.defineProperty(exports, "sortMealsByReservationId", {
  enumerable: true,
  get: function get() {
    return _selectors.sortMealsByReservationId;
  }
});
Object.defineProperty(exports, "superRoleFilter", {
  enumerable: true,
  get: function get() {
    return _formatters.superRoleFilter;
  }
});
Object.defineProperty(exports, "swapKeysAndValues", {
  enumerable: true,
  get: function get() {
    return _formatters.swapKeysAndValues;
  }
});
Object.defineProperty(exports, "ternaryCondition", {
  enumerable: true,
  get: function get() {
    return _validators.ternaryCondition;
  }
});
Object.defineProperty(exports, "timeTrackerSeconds", {
  enumerable: true,
  get: function get() {
    return _services.timeTrackerSeconds;
  }
});
Object.defineProperty(exports, "tracingCookie", {
  enumerable: true,
  get: function get() {
    return _logger.tracingCookie;
  }
});
Object.defineProperty(exports, "transformBartIdToOperaId", {
  enumerable: true,
  get: function get() {
    return _selectors.transformBartIdToOperaId;
  }
});
Object.defineProperty(exports, "transformLabels", {
  enumerable: true,
  get: function get() {
    return _getters.transformLabels;
  }
});
Object.defineProperty(exports, "updateAmendPageAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateAmendPageAnalytics;
  }
});
Object.defineProperty(exports, "updateAncillariesAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateAncillariesAnalytics;
  }
});
Object.defineProperty(exports, "updateConfirmationAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateConfirmationAnalytics;
  }
});
Object.defineProperty(exports, "updateConfirmationPageAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateConfirmationPageAnalytics;
  }
});
Object.defineProperty(exports, "updateDashboardAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateDashboardAnalytics;
  }
});
Object.defineProperty(exports, "updateHotelDisplayPageAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateHotelDisplayPageAnalytics;
  }
});
Object.defineProperty(exports, "updateSearchResultsAnalytics", {
  enumerable: true,
  get: function get() {
    return _services.updateSearchResultsAnalytics;
  }
});
Object.defineProperty(exports, "updateSilentSubstLocalStorage", {
  enumerable: true,
  get: function get() {
    return _selectors.updateSilentSubstLocalStorage;
  }
});
Object.defineProperty(exports, "upperFirst", {
  enumerable: true,
  get: function get() {
    return _formatters.upperFirst;
  }
});
Object.defineProperty(exports, "upperOnlyFirst", {
  enumerable: true,
  get: function get() {
    return _formatters.upperOnlyFirst;
  }
});
Object.defineProperty(exports, "uppercaseAndReplace", {
  enumerable: true,
  get: function get() {
    return _formatters.uppercaseAndReplace;
  }
});
Object.defineProperty(exports, "useAgentMemo", {
  enumerable: true,
  get: function get() {
    return _store.useAgentMemo;
  }
});
Object.defineProperty(exports, "useAppData", {
  enumerable: true,
  get: function get() {
    return _store.useAppData;
  }
});
Object.defineProperty(exports, "useAppDataDispatch", {
  enumerable: true,
  get: function get() {
    return _store.useAppDataDispatch;
  }
});
Object.defineProperty(exports, "useBookingConfimationData", {
  enumerable: true,
  get: function get() {
    return _hooks.useBookingConfimationData;
  }
});
Object.defineProperty(exports, "useBookingSpinner", {
  enumerable: true,
  get: function get() {
    return _hooks.useBookingSpinner;
  }
});
Object.defineProperty(exports, "useCompanyDetails", {
  enumerable: true,
  get: function get() {
    return _hooks.useCompanyDetails;
  }
});
Object.defineProperty(exports, "useCustomLocale", {
  enumerable: true,
  get: function get() {
    return _hooks.useCustomLocale;
  }
});
Object.defineProperty(exports, "useDebounce", {
  enumerable: true,
  get: function get() {
    return _hooks.useDebounce;
  }
});
Object.defineProperty(exports, "useDiscountRateInfoHotelAvailability", {
  enumerable: true,
  get: function get() {
    return _hooks.useDiscountRateInfoHotelAvailability;
  }
});
Object.defineProperty(exports, "useElementDimensions", {
  enumerable: true,
  get: function get() {
    return _hooks.useElementDimensions;
  }
});
Object.defineProperty(exports, "useElementVisited", {
  enumerable: true,
  get: function get() {
    return _hooks.useElementVisited;
  }
});
Object.defineProperty(exports, "useFeatureSwitch", {
  enumerable: true,
  get: function get() {
    return _hooks.useFeatureSwitch;
  }
});
Object.defineProperty(exports, "useFeatureToggle", {
  enumerable: true,
  get: function get() {
    return _hooks.useFeatureToggle;
  }
});
Object.defineProperty(exports, "useFeatureToggleData", {
  enumerable: true,
  get: function get() {
    return _store.useFeatureToggleData;
  }
});
Object.defineProperty(exports, "useForDiscountedRateFlag", {
  enumerable: true,
  get: function get() {
    return _hooks.useForDiscountedRateFlag;
  }
});
Object.defineProperty(exports, "useForDiscountedRateMicroSite", {
  enumerable: true,
  get: function get() {
    return _hooks.useForDiscountedRateMicroSite;
  }
});
Object.defineProperty(exports, "useGetCountryLanguage", {
  enumerable: true,
  get: function get() {
    return _hooks.useGetCountryLanguage;
  }
});
Object.defineProperty(exports, "useGetDiscountRateComapnyId", {
  enumerable: true,
  get: function get() {
    return _hooks.useGetDiscountRateComapnyId;
  }
});
Object.defineProperty(exports, "useGetDiscountRateReservationData", {
  enumerable: true,
  get: function get() {
    return _hooks.useGetDiscountRateReservationData;
  }
});
Object.defineProperty(exports, "useHotelAvailability", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelAvailability;
  }
});
Object.defineProperty(exports, "useHotelAvailabilityBB", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelAvailabilityBB;
  }
});
Object.defineProperty(exports, "useHotelAvailabilityCCUI", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelAvailabilityCCUI;
  }
});
Object.defineProperty(exports, "useHotelAvailabilityDiscountRate", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelAvailabilityDiscountRate;
  }
});
Object.defineProperty(exports, "useHotelBrands", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelBrands;
  }
});
Object.defineProperty(exports, "useHotelRatesInformationBB", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelRatesInformationBB;
  }
});
Object.defineProperty(exports, "useHotelRatesInformationCCUI", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelRatesInformationCCUI;
  }
});
Object.defineProperty(exports, "useHotelRatesInformationDiscountRate", {
  enumerable: true,
  get: function get() {
    return _hooks.useHotelRatesInformationDiscountRate;
  }
});
Object.defineProperty(exports, "useIPageSubmission", {
  enumerable: true,
  get: function get() {
    return _hooks.useIPageSubmission;
  }
});
Object.defineProperty(exports, "useIsExternalSearch", {
  enumerable: true,
  get: function get() {
    return _hooks.useIsExternalSearch;
  }
});
Object.defineProperty(exports, "useLocalStorage", {
  enumerable: true,
  get: function get() {
    return _hooks.useLocalStorage;
  }
});
Object.defineProperty(exports, "useMutationRequest", {
  enumerable: true,
  get: function get() {
    return _hooks.useMutationRequest;
  }
});
Object.defineProperty(exports, "useOrientation", {
  enumerable: true,
  get: function get() {
    return _hooks.useOrientation;
  }
});
Object.defineProperty(exports, "usePackages", {
  enumerable: true,
  get: function get() {
    return _hooks.usePackages;
  }
});
Object.defineProperty(exports, "usePaymentMethod", {
  enumerable: true,
  get: function get() {
    return _hooks.usePaymentMethod;
  }
});
Object.defineProperty(exports, "usePaymentPaypal", {
  enumerable: true,
  get: function get() {
    return _hooks.usePaymentPaypal;
  }
});
Object.defineProperty(exports, "usePollBasketStatus", {
  enumerable: true,
  get: function get() {
    return _hooks.usePollBasketStatus;
  }
});
Object.defineProperty(exports, "useQueryRequest", {
  enumerable: true,
  get: function get() {
    return _hooks.useQueryRequest;
  }
});
Object.defineProperty(exports, "useRestMutationRequest", {
  enumerable: true,
  get: function get() {
    return _hooks.useRestMutationRequest;
  }
});
Object.defineProperty(exports, "useRestQueryRequest", {
  enumerable: true,
  get: function get() {
    return _hooks.useRestQueryRequest;
  }
});
Object.defineProperty(exports, "useScreenSize", {
  enumerable: true,
  get: function get() {
    return _hooks.useScreenSize;
  }
});
Object.defineProperty(exports, "useScrollVisibility", {
  enumerable: true,
  get: function get() {
    return _hooks.useScrollVisibility;
  }
});
Object.defineProperty(exports, "useScrolledPast", {
  enumerable: true,
  get: function get() {
    return _hooks.useScrolledPast;
  }
});
Object.defineProperty(exports, "useSessionStorage", {
  enumerable: true,
  get: function get() {
    return _hooks.useSessionStorage;
  }
});
Object.defineProperty(exports, "useSetOrientation", {
  enumerable: true,
  get: function get() {
    return _hooks.useSetOrientation;
  }
});
Object.defineProperty(exports, "useSetScreenSize", {
  enumerable: true,
  get: function get() {
    return _hooks.useSetScreenSize;
  }
});
Object.defineProperty(exports, "useSilentRoomsMatch", {
  enumerable: true,
  get: function get() {
    return _hooks.useSilentRoomsMatch;
  }
});
Object.defineProperty(exports, "useStaticHotelInformation", {
  enumerable: true,
  get: function get() {
    return _hooks.useStaticHotelInformation;
  }
});
Object.defineProperty(exports, "useUpdateRateName", {
  enumerable: true,
  get: function get() {
    return _hooks.useUpdateRateName;
  }
});
Object.defineProperty(exports, "useUpdateSearchParams", {
  enumerable: true,
  get: function get() {
    return _hooks.useUpdateSearchParams;
  }
});
Object.defineProperty(exports, "useUserData", {
  enumerable: true,
  get: function get() {
    return _store.useUserData;
  }
});
Object.defineProperty(exports, "useUserDetails", {
  enumerable: true,
  get: function get() {
    return _hooks.useUserDetails;
  }
});
Object.defineProperty(exports, "waitForElement", {
  enumerable: true,
  get: function get() {
    return _helpers.waitForElement;
  }
});
var _formatters = require("./formatters");
var _getters = require("./getters");
var _globalConstants = require("./global-constants");
var _client = require("./gql/client");
var _helpers = require("./helpers");
var _hooks = require("./hooks");
var _clientLogger = _interopRequireDefault(require("./logger/clientLogger"));
var _logger = require("./logger/logger");
var _queriesLogger = _interopRequireDefault(require("./logger/queriesLogger"));
var _bookingStatus = require("./mappers/bookingStatus");
var _selectors = require("./selectors");
var _server = require("./server");
var _services = require("./services");
var _store = require("./store");
var _validators = require("./validators");
var _mockData = require("./mockData");