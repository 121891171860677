"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorTooltip = ErrorTooltip;
exports.InfoTooltip = InfoTooltip;
exports.TooltipArrow = TooltipArrow;
exports.TooltipTrigger = exports.TooltipRoot = exports.TooltipProvider = exports.TooltipContent = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var TooltipPrimitive = _interopRequireWildcard(require("@radix-ui/react-tooltip"));
var _server = require("@whitbread-eos/utils/server");
var _lucideReact = require("lucide-react");
var _image = _interopRequireDefault(require("next/image"));
var _react = _interopRequireWildcard(require("react"));
var React = _react;
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className", "sideOffset"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var TooltipProvider = exports.TooltipProvider = TooltipPrimitive.Provider;
var TooltipRoot = exports.TooltipRoot = TooltipPrimitive.Root;
var TooltipTrigger = exports.TooltipTrigger = TooltipPrimitive.Trigger;
var TooltipContent = exports.TooltipContent = React.forwardRef((_ref, ref) => {
  var {
      className,
      sideOffset = 4
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(TooltipPrimitive.Content, _objectSpread({
    ref: ref,
    sideOffset: sideOffset,
    className: (0, _server.cn)(tooltipContentBaseStyle, 'z-50 overflow-hidden bg-popover text-popover-foreground flex', className)
  }, props));
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
function TooltipArrow(_ref2) {
  var {
    className
  } = _ref2;
  return (0, _jsxRuntime.jsx)("div", {
    className: (0, _server.cn)('h-0 w-0 border-x-[12px] border-x-transparent border-b-[12px] absolute top-[-12px] left-4', className)
  });
}
function InfoTooltip(_ref3) {
  var {
    children,
    content,
    className,
    arrowClassName,
    testId,
    mobile = false
  } = _ref3;
  var [open, setOpen] = (0, _react.useState)(false);
  if (mobile && open) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [children, (0, _jsxRuntime.jsxs)("div", {
        className: (0, _server.cn)(tooltipContentBaseStyle, 'bg-tooltipInfo relative mt-4 hidden mobile:flex pt-12', className),
        children: [(0, _jsxRuntime.jsx)("div", {
          className: "absolute top-[18px] right-[18px]",
          children: (0, _jsxRuntime.jsx)(_lucideReact.X, {
            className: "stroke-secondaryColor",
            onClick: () => setOpen(false)
          })
        }), content, (0, _jsxRuntime.jsx)(TooltipArrow, {
          className: (0, _server.cn)('border-b-tooltipInfo', arrowClassName)
        })]
      })]
    });
  }
  return (0, _jsxRuntime.jsx)(TooltipProvider, {
    children: (0, _jsxRuntime.jsxs)(TooltipRoot, {
      open: open,
      children: [(0, _jsxRuntime.jsx)(TooltipTrigger, {
        asChild: true,
        onClick: () => setOpen(true),
        children: children
      }), (0, _jsxRuntime.jsxs)(TooltipContent, {
        side: "bottom",
        align: "start",
        className: (0, _server.cn)('bg-tooltipInfo mobile:hidden overflow-visible relative mt-3', className),
        onPointerDownOutside: () => setOpen(false),
        "data-testid": testId,
        children: [content, (0, _jsxRuntime.jsx)(TooltipArrow, {
          className: (0, _server.cn)('border-b-tooltipInfo', arrowClassName)
        })]
      })]
    })
  });
}
function ErrorTooltip(_ref4) {
  var {
    children,
    content,
    open,
    icon,
    className,
    arrowClassName,
    testId,
    mobile = false
  } = _ref4;
  if (mobile && open) {
    return (0, _jsxRuntime.jsxs)("div", {
      "data-testid": testId,
      className: (0, _server.cn)(tooltipContentBaseStyle, 'bg-tooltipError relative mt-4 hidden mobile:flex', className),
      children: [icon && (0, _jsxRuntime.jsx)(_image.default, {
        src: icon,
        alt: "error",
        width: 16,
        height: 16,
        className: "mt-0.5 mr-2"
      }), content, (0, _jsxRuntime.jsx)(TooltipArrow, {
        className: (0, _server.cn)('border-b-tooltipError', arrowClassName)
      })]
    });
  }
  return (0, _jsxRuntime.jsx)(TooltipProvider, {
    children: (0, _jsxRuntime.jsxs)(TooltipRoot, {
      open: open,
      children: [(0, _jsxRuntime.jsx)(TooltipTrigger, {
        asChild: true,
        children: children
      }), (0, _jsxRuntime.jsxs)(TooltipContent, {
        side: "bottom",
        align: "start",
        className: (0, _server.cn)('bg-tooltipError mobile:hidden overflow-visible relative mt-3', className),
        "data-testid": testId,
        children: [icon && (0, _jsxRuntime.jsx)(_image.default, {
          src: icon,
          alt: "error",
          width: 16,
          height: 16,
          className: "mt-0.5 mr-2"
        }), content, (0, _jsxRuntime.jsx)(TooltipArrow, {
          className: (0, _server.cn)('border-b-tooltipError', arrowClassName)
        })]
      })]
    })
  });
}
var tooltipContentBaseStyle = 'items-start text-sm font-normal min-h-9 rounded-[3px] px-4 py-2 border-0';