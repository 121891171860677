"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BBAllGuestsDetailsForm;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _BBGuestDetailsGeneralRoom = _interopRequireDefault(require("../BBDynamicGuestDetailsForm/BBGuestDetailsGeneralRoom"));
var _BBGuestDetailsRoom = _interopRequireDefault(require("../BBGuestDetailsForm/BBGuestDetailsRoom"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BBAllGuestsDetailsForm(_ref) {
  var {
    control,
    formField,
    errors,
    reset,
    handleResetField
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    numberOfRooms = 1,
    labels,
    queryClient,
    guestList,
    reservationByIdList,
    setGuestUser,
    isDynamicSearchVisible,
    isAmendPage,
    bbEmployeeList,
    defaultGuest,
    onEditBbInput,
    isAccompanyingGuestDetailsEnabled,
    accessLevel,
    selfBookerDetails,
    baseDataTestIdAccompayningGuestDetails
  } = formField.props;
  var {
    testid,
    dropdownOptions,
    name
  } = formField;
  function renderContent() {
    return [...Array(numberOfRooms)].map((value, index) => {
      var _reservationByIdList$, _reservationByIdList$2, _reservationByIdList$3;
      var isFirstRoom = index === 0;
      var hasOneRoom = numberOfRooms === 1;
      var firstRoomOfMultipleRooms = isFirstRoom && numberOfRooms > 1;
      var hasMoreThanOneAdult = ((_reservationByIdList$ = reservationByIdList[index]) === null || _reservationByIdList$ === void 0 || (_reservationByIdList$ = _reservationByIdList$.roomStay) === null || _reservationByIdList$ === void 0 ? void 0 : _reservationByIdList$.adultsNumber) > 1;
      var accompanyingGuestDetailsTitleStyle = hasOneRoom ? guestDetailsTitleStyle : smallAccompanyingGDTitleStyle(firstRoomOfMultipleRooms);
      var guestTitleNeedsMargin = hasOneRoom && hasMoreThanOneAdult && accessLevel !== _api.BUSINESS_BOOKER_USER_ROLES.SELF && isAccompanyingGuestDetailsEnabled;
      return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, guestDetailsSectionStyle), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: 'column',
          mr: {
            xl: '2xl'
          },
          children: [isFirstRoom && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, guestDetailsTitleStyle), {}, {
            mb: {
              xl: guestTitleNeedsMargin ? '2xl' : ''
            },
            "data-testid": (0, _utils.formatDataTestId)('GuestDetailsBBContainer', 'GuestDetailsTitle'),
            children: t('booking.contactDetails.guestDetails')
          })), accessLevel === _api.BUSINESS_BOOKER_USER_ROLES.SELF && ((_reservationByIdList$2 = reservationByIdList[index]) === null || _reservationByIdList$2 === void 0 || (_reservationByIdList$2 = _reservationByIdList$2.roomStay) === null || _reservationByIdList$2 === void 0 ? void 0 : _reservationByIdList$2.adultsNumber) > 1 && isAccompanyingGuestDetailsEnabled ? (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)('GuestDetailsBBContainer', 'SelfBookerDetails')
          }, accompanyingGuestDetailsDescription), {}, {
            children: selfBookerDetails
          })) : (0, _jsxRuntime.jsx)(_BBGuestDetailsRoom.default, {
            t: t,
            roomNumber: index,
            numberOfRooms: numberOfRooms,
            labels: labels,
            testid: testid,
            control: control,
            errors: errors,
            dropdownOptions: dropdownOptions,
            formField: formField,
            queryClient: queryClient,
            guestList: guestList,
            setGuestUser: setGuestUser,
            index: index,
            reset: reset,
            isDynamicSearchVisible: isDynamicSearchVisible,
            isAmendPage: isAmendPage,
            bbEmployeeList: bbEmployeeList,
            defaultGuest: defaultGuest,
            onEditBbInput: onEditBbInput,
            componentName: name,
            reservationByIdList: reservationByIdList,
            handleResetField: handleResetField
          }, testid)]
        }), isAccompanyingGuestDetailsEnabled && ((_reservationByIdList$3 = reservationByIdList[index]) === null || _reservationByIdList$3 === void 0 || (_reservationByIdList$3 = _reservationByIdList$3.roomStay) === null || _reservationByIdList$3 === void 0 ? void 0 : _reservationByIdList$3.adultsNumber) > 1 && (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: 'column',
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, accompanyingGuestDetailsTitleStyle), {}, {
            "data-testid": (0, _utils.formatDataTestId)('AccompanyingGuestDetailsBBContainer', 'AccompanyingGDTitle'),
            children: t('booking.contactDetails.accompanyingGuest.title')
          })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, accompanyingGuestDetailsDescription), {}, {
            "data-testid": (0, _utils.formatDataTestId)('AccompanyingGuestDetailsBBContainer', 'AccompanyingGDDescription'),
            children: t('booking.accompanyingGuest.description')
          })), (0, _jsxRuntime.jsx)(_BBGuestDetailsGeneralRoom.default, {
            t: t,
            roomNumber: index,
            labels: labels,
            testid: (0, _utils.formatDataTestId)(baseDataTestIdAccompayningGuestDetails, 'Form'),
            componentName: 'bbAccompanyingGuestDetails',
            control: control,
            errors: errors,
            dropdownOptions: dropdownOptions,
            formField: formField,
            queryClient: queryClient,
            guestList: guestList,
            setGuestUser: setGuestUser,
            index: index,
            handleResetField: handleResetField,
            isDynamicSearchVisible: isDynamicSearchVisible,
            isAmendPage: isAmendPage,
            bbEmployeeList: bbEmployeeList,
            defaultGuest: defaultGuest,
            onEditBbInput: onEditBbInput
          }, (0, _utils.formatDataTestId)(baseDataTestIdAccompayningGuestDetails, 'Form'))]
        })]
      }));
    });
  }
  return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
    direction: "column",
    justifyContent: 'space-between'
  }, _objectSpread({}, formField.styles)), {}, {
    "data-testid": (0, _utils.formatDataTestId)(testid, 'Container'),
    children: renderContent()
  }));
}
var guestDetailsTitleStyle = {
  as: 'h3',
  color: 'darkGrey1',
  fontSize: 'xl',
  fontWeight: 'semibold',
  lineHeight: '3',
  mt: {
    mobile: 'xl'
  }
};
var smallAccompanyingGDTitleStyle = firstRoomOfMultipleRooms => {
  return {
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: '3',
    color: 'darkGrey1',
    mt: {
      xl: firstRoomOfMultipleRooms ? '8.75rem' : '4.75rem',
      lg: 'lg',
      mobile: 'lg'
    }
  };
};
var accompanyingGuestDetailsDescription = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '3',
  textAlign: 'left',
  color: 'darkGrey1',
  marginTop: 'md'
};
var guestDetailsSectionStyle = {
  flexDirection: {
    xl: 'row',
    lg: 'column',
    mobile: 'column'
  },
  justifyContent: 'space-between'
};