"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryRoomInformation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _router = require("next/router");
var _react2 = require("react");
var _uuid = require("uuid");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingSummaryRoomInformation(_ref) {
  var _router$query;
  var {
    t,
    roomInformation,
    prefixDataTestId,
    isExtrasDisplayed
  } = _ref;
  var getAdultsPluralLabel = (0, _react2.useCallback)(value => value === 1 ? t('account.dashboard.adult') : t('account.dashboard.adults'), [t]);
  var getChildrenPluralLabel = (0, _react2.useCallback)(value => value === 1 ? t('booking.mealChoose.child') : t('account.dashboard.children'), [t]);
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'RoomInformation');
  var hotelPhoneNumber = t('booking.hotel.summary.accessibleContact');
  var isSilentFeatureFlagEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_SILENT_SUBSTITUTION
  });
  var router = (0, _router.useRouter)();
  var reservationId = (router === null || router === void 0 || (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.reservationId) || '';
  var currentReservationRoomData = isSilentFeatureFlagEnabled ? (0, _utils.getCurrentReservationStorageData)(reservationId) : {};
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    flexDirection: "column",
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: roomInformation.map((room, index) => {
      var _currentReservationRo, _room$selectedMeals$a, _room$selectedExtrasL, _room$selectedMeals$a2, _room$selectedMeals, _room$selectedExtrasL2, _room$selectedExtrasL3, _room$accessibleRoom, _room$accessibleRoom2;
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          py: {
            mobile: 'md',
            lg: 'lg'
          },
          children: (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles))
        }), (0, _jsxRuntime.jsxs)(_react.Box, {
          children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, infoTextStyle), {}, {
            fontWeight: "semibold",
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'RoomNumber'),
            children: [t('booking.summary.room'), " ", "".concat(index + 1, " "), room.roomName && (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
              as: "span"
            }, roomInfoTextStyle), {}, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'RoomName'),
              children: ["(", (0, _utils.displayStorageSubstitutionLabels)(currentReservationRoomData === null || currentReservationRoomData === void 0 || (_currentReservationRo = currentReservationRoomData.value) === null || _currentReservationRo === void 0 ? void 0 : _currentReservationRo[index], room.roomName, isSilentFeatureFlagEnabled), ")"]
            }))]
          })), !!room.nrAdults && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomInfoTextStyle), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AdultsNumber'),
            children: "".concat(room.nrAdults, " ").concat(getAdultsPluralLabel(room.nrAdults))
          })), !!room.nrChildren && room.nrChildren > 0 && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomInfoTextStyle), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ChildrenNumber'),
            children: "".concat(room.nrChildren, " ").concat(getChildrenPluralLabel(room.nrChildren))
          })), room.selectedMeals && ((_room$selectedMeals$a = room.selectedMeals.adultsMeals) === null || _room$selectedMeals$a === void 0 ? void 0 : _room$selectedMeals$a.length) === 0 && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomInfoTextStyle), {}, {
            fontSize: "sm",
            pt: "sm",
            lineHeight: 2,
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'NoMealsSelected'),
            children: t('booking.summary.noMeals')
          })), (room === null || room === void 0 ? void 0 : room.selectedExtrasList) && (room === null || room === void 0 || (_room$selectedExtrasL = room.selectedExtrasList) === null || _room$selectedExtrasL === void 0 || (_room$selectedExtrasL = _room$selectedExtrasL.packagesSelection) === null || _room$selectedExtrasL === void 0 ? void 0 : _room$selectedExtrasL.length) === 0 && isExtrasDisplayed && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomInfoTextStyle), {}, {
            fontSize: "sm",
            pt: "sm",
            lineHeight: 2,
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'NoExtrasSelected'),
            children: t('ancillaries.extras.not.available')
          })), room.selectedMeals && room.selectedMeals.adultsMeals.length > 0 && (0, _jsxRuntime.jsx)(_react.Box, {
            pt: "sm",
            children: (_room$selectedMeals$a2 = room.selectedMeals.adultsMeals) === null || _room$selectedMeals$a2 === void 0 ? void 0 : _room$selectedMeals$a2.map(adultMeal => {
              var _adultMeal$noSelectio;
              return (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
                fontSize: "sm",
                lineHeight: 2
              }, roomInfoTextStyle), {}, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AdultMeal'),
                children: "".concat(adultMeal.title, " ").concat(t('upsell.label.for'), " ").concat(adultMeal.noSelections, " ").concat(getAdultsPluralLabel((_adultMeal$noSelectio = adultMeal.noSelections) !== null && _adultMeal$noSelectio !== void 0 ? _adultMeal$noSelectio : 0))
              }), (0, _uuid.v4)());
            })
          }), room === null || room === void 0 || (_room$selectedMeals = room.selectedMeals) === null || _room$selectedMeals === void 0 ? void 0 : _room$selectedMeals.childrenMeals.map(childMeal => {
            var _childMeal$noSelectio;
            return (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              fontSize: "sm",
              lineHeight: 2
            }, roomInfoTextStyle), {}, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ChildrenMeal'),
              children: "".concat(childMeal.title, " ").concat(t('upsell.label.for'), " ").concat(childMeal.noSelections, " ").concat(getChildrenPluralLabel((_childMeal$noSelectio = childMeal.noSelections) !== null && _childMeal$noSelectio !== void 0 ? _childMeal$noSelectio : 0))
            }), (0, _uuid.v4)());
          }), (room === null || room === void 0 ? void 0 : room.selectedExtrasList) && (room === null || room === void 0 || (_room$selectedExtrasL2 = room.selectedExtrasList) === null || _room$selectedExtrasL2 === void 0 || (_room$selectedExtrasL2 = _room$selectedExtrasL2.packagesSelection) === null || _room$selectedExtrasL2 === void 0 ? void 0 : _room$selectedExtrasL2.length) > 0 && isExtrasDisplayed && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
            children: room === null || room === void 0 || (_room$selectedExtrasL3 = room.selectedExtrasList) === null || _room$selectedExtrasL3 === void 0 || (_room$selectedExtrasL3 = _room$selectedExtrasL3.packagesSelection) === null || _room$selectedExtrasL3 === void 0 || (_room$selectedExtrasL3 = _room$selectedExtrasL3.sort()) === null || _room$selectedExtrasL3 === void 0 ? void 0 : _room$selectedExtrasL3.map((extrasPackage, index) => {
              return (0, _jsxRuntime.jsx)(_react.Box, {
                pt: "sm",
                children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomInfoTextStyle), {}, {
                  lineHeight: 2,
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, extrasPackage === null || extrasPackage === void 0 ? void 0 : extrasPackage.id),
                  children: (0, _utils.extrasNamingCheck)(t, extrasPackage === null || extrasPackage === void 0 ? void 0 : extrasPackage.id)
                }))
              }, index);
            })
          }), (room === null || room === void 0 || (_room$accessibleRoom = room.accessibleRoom) === null || _room$accessibleRoom === void 0 ? void 0 : _room$accessibleRoom.isAccessible) && (room === null || room === void 0 || (_room$accessibleRoom2 = room.accessibleRoom) === null || _room$accessibleRoom2 === void 0 ? void 0 : _room$accessibleRoom2.phoneNumber) && (0, _jsxRuntime.jsx)(_react.Box, {
            mt: "lg",
            children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
              maxWidth: "full",
              status: "info",
              description: hotelPhoneNumber.replace('{{PhoneNumber.hotelNumber}}', room.accessibleRoom.phoneNumber),
              variant: "accessible",
              svg: (0, _jsxRuntime.jsx)(_atoms.Accessible32, {})
            })
          })]
        })]
      }, room.roomName);
    })
  });
}
var infoTextStyle = {
  lineHeight: '3',
  color: 'darkGrey1',
  fontSize: 'md'
};
var roomInfoTextStyle = {
  color: 'darkGrey1',
  fontWeight: 'normal',
  fontSize: 'md'
};
var dividerStyles = {
  borderColor: 'lightGrey4',
  opacity: '1'
};