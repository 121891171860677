"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Input;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = _interopRequireWildcard(require("react"));
var _icons = require("../../assets/icons");
var _formatters = require("../../utils/formatters");
var _Tooltip = _interopRequireDefault(require("../Tooltip"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getDisplayProps = _ref => {
  var _ref2;
  var {
    label,
    showLabel,
    isDisabled,
    helperText,
    charLimit,
    error,
    showIcon,
    value
  } = _ref;
  return {
    displayLabel: label && (showLabel || !isDisabled),
    displayUnderField: (_ref2 = helperText !== null && helperText !== void 0 ? helperText : charLimit) !== null && _ref2 !== void 0 ? _ref2 : error,
    displayHelperText: helperText && !error || helperText && error && isDisabled,
    displayCharLimit: charLimit && !isDisabled,
    displayIcon: showIcon && !isDisabled,
    isValid: !error && value
  };
};
function Input(props) {
  var {
    autoComplete = 'on',
    type = 'text',
    label,
    placeholderText,
    name,
    showLabel,
    isDisabled,
    isAutoFocused,
    helperText,
    charLimit,
    maxLength,
    error,
    useTooltip,
    useCustomTooltip,
    value,
    onChange,
    onBlur,
    onClick,
    onKeyDown,
    showRightElement = false,
    showIcon = false,
    showText = 'Show',
    hideText = 'Hide',
    inputRef,
    className,
    styles,
    isInputAriaRequired = false,
    extraTooltipStyles = {}
  } = props;
  var {
    displayLabel,
    displayUnderField,
    displayHelperText,
    displayCharLimit,
    displayIcon,
    isValid
  } = getDisplayProps({
    label,
    showLabel,
    isDisabled,
    helperText,
    charLimit,
    error,
    showIcon,
    value
  });
  var [isPasswordVisible, setIsPasswordVisible] = (0, _react2.useState)(false);
  var [focused, setFocused] = (0, _react2.useState)(false);
  var handleFocus = val => setFocused(val);
  var handleClick = () => setIsPasswordVisible(!isPasswordVisible);
  var baseDataTestId = 'input';
  var wrapperStyles = _objectSpread(_objectSpread({}, inputWrapperStyle), styles === null || styles === void 0 ? void 0 : styles.inputWrapperStyles);
  var inputElementStyles = _objectSpread(_objectSpread({}, inputStyle(error)), styles === null || styles === void 0 ? void 0 : styles.inputElementStyles);
  return (0, _jsxRuntime.jsxs)(_react.FormControl, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    isInvalid: error && !isDisabled,
    children: [displayLabel && (0, _jsxRuntime.jsx)(_react.FormLabel, _objectSpread(_objectSpread({
      "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-label")),
      pos: "absolute"
    }, labelStyle(error, value)), {}, {
      htmlFor: name,
      children: label
    })), (0, _jsxRuntime.jsx)(_Tooltip.default, _objectSpread(_objectSpread(_objectSpread({}, extraTooltipStyles), tooltipStyle), {}, {
      description: error,
      variant: "inlineError",
      placement: "bottom-start",
      isDisabled: !error || !useTooltip,
      isOpen: error && useTooltip,
      svg: (0, _jsxRuntime.jsx)(_icons.Error24, {}),
      children: (0, _jsxRuntime.jsxs)(_react.InputGroup, {
        children: [(0, _jsxRuntime.jsx)(_react.Input, _objectSpread(_objectSpread({
          autoComplete: autoComplete,
          "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name))
        }, inputElementStyles), {}, {
          errorBorderColor: error && 'error',
          maxLength: maxLength !== null && maxLength !== void 0 ? maxLength : charLimit,
          ref: inputRef,
          isDisabled: isDisabled,
          id: name,
          placeholder: placeholderText,
          value: value,
          onChange: handleChange,
          onBlur: onBlur,
          onBlurCapture: () => handleFocus(false),
          autoFocus: isAutoFocused,
          type: type,
          className: className !== null && className !== void 0 ? className : '',
          onFocus: () => handleFocus(true),
          onClick: onClick,
          onKeyDown: onKeyDown,
          isRequired: isInputAriaRequired
        })), type === 'password' && showRightElement && (0, _jsxRuntime.jsx)(_react.InputRightElement, {
          width: "4.5rem",
          children: (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-SwitchShowPass")),
            h: "1.75rem",
            size: "sm",
            onClick: handleClick,
            children: isPasswordVisible ? hideText : showText
          })
        }), displayIcon && error && !focused && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, iconStyle), {}, {
          "data-testid": "inputIconError",
          children: (0, _jsxRuntime.jsx)(_icons.Error24, {})
        })), displayIcon && isValid && !focused && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, iconStyle), {}, {
          "data-testid": "inputIconSuccess",
          children: (0, _jsxRuntime.jsx)(_icons.Success24, {})
        }))]
      })
    })), displayUnderField && (0, _jsxRuntime.jsxs)(_react.Flex, {
      w: "full",
      dir: "column",
      justify: !helperText && !error ? 'flex-end' : 'space-between',
      children: [error && !useTooltip && renderFieldErrorMessage(), displayHelperText && (0, _jsxRuntime.jsx)(_react.FormHelperText, _objectSpread(_objectSpread({
        "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-FormHelperText"))
      }, helperTextStyle(isDisabled)), {}, {
        children: helperText
      })), handleDisplayCharLimit(displayCharLimit, baseDataTestId, error, value, charLimit, name)]
    })]
  }));
  function handleChange(event) {
    var _event$target;
    if (onChange) onChange(event === null || event === void 0 || (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value);
  }
  function renderFieldErrorMessage() {
    return !useCustomTooltip ? (0, _jsxRuntime.jsx)(_react.FormErrorMessage, _objectSpread(_objectSpread({
      "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-FormErrorMessage"))
    }, errorMsgStyle), {}, {
      children: error
    })) : (0, _jsxRuntime.jsxs)(_react.FormErrorMessage, _objectSpread(_objectSpread({
      "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-FormErrorMessage"))
    }, customErrorMsgStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_icons.Error, {}), (0, _jsxRuntime.jsx)(_react.Box, {
        pl: "0.5rem",
        children: error
      })]
    }));
  }
}
var handleDisplayCharLimit = (displayCharLimit, baseDataTestId, error, value, charLimit, name) => {
  if (displayCharLimit) {
    var _value$length;
    return (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _formatters.formatDataTestId)(baseDataTestId, "".concat(name, "-CharLimit"))
    }, charLimitStyle(error)), {}, {
      children: [(_value$length = value === null || value === void 0 ? void 0 : value.length) !== null && _value$length !== void 0 ? _value$length : 0, "/", charLimit]
    }));
  }
};
var inputWrapperStyle = {
  borderRadius: 'xs',
  zIndex: 0,
  borderColor: 'lightGrey1',
  _hover: {
    borderColor: 'darkGrey1'
  },
  _focus: {
    zIndex: '0'
  }
};
var inputStyle = error => {
  return {
    h: '3.5rem',
    zIndex: 0,
    borderWidth: '1px',
    borderColor: 'var(--chakra-colors-lightGrey1)',
    _hover: {
      borderColor: 'none'
    },
    _focus: {
      zIndex: '0',
      borderWidth: '2px',
      borderColor: error ? 'error' : 'primary'
    },
    _placeholder: {
      color: 'darkGrey2',
      _focus: {
        color: 'success'
      },
      _hover: {
        color: 'primary'
      }
    },
    _autofill: {
      boxShadow: '0 0 0 1000px #FFFFFF inset'
    }
  };
};
var labelStyle = (error, value) => {
  return {
    h: '1.25rem',
    w: 'fit-content',
    fontSize: 'sm',
    align: 'center',
    px: 'xs',
    fontWeight: 'normal',
    ml: '0.750rem',
    top: '-0.625rem',
    backgroundColor: 'baseWhite',
    zIndex: '1',
    color: error ? 'error' : 'darkGrey1',
    display: value ? 'block' : 'none',
    _focus: {
      color: error ? 'error' : 'primary',
      display: 'block'
    },
    lineHeight: '1'
  };
};
var helperTextStyle = isDisabled => {
  return {
    fontSize: 'xs',
    color: isDisabled ? 'lightGrey1' : 'darkGrey1',
    ml: 'md',
    mt: '0.375rem'
  };
};
var charLimitStyle = error => {
  return {
    fontSize: 'xs',
    color: error ? 'error' : 'darkGrey2',
    mr: 'sm',
    mt: '0.375rem'
  };
};
var errorMsgStyle = {
  fontSize: 'xs',
  color: 'error',
  ml: 'md',
  mt: 'sm'
};
var customErrorMsgStyle = {
  fontSize: 'sm',
  mt: 'xmd',
  w: 'full',
  h: '2.5rem',
  bgColor: 'tooltipError',
  color: 'darkGrey1',
  padding: '1rem',
  borderRadius: 'var(--chakra-space-xs)',
  _before: {
    content: '" "',
    position: 'absolute',
    top: '4rem',
    left: '1.531rem',
    width: 'var(--chakra-space-xmd)',
    height: 'var(--chakra-space-xmd)',
    transform: 'rotate(-45deg)',
    bgColor: 'tooltipError'
  }
};
var tooltipStyle = {
  h: '2.25rem',
  display: 'flex',
  alignContent: 'center'
};
var iconStyle = {
  ml: 'md',
  alignSelf: 'center'
};