"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BillingAddress;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _billingAddressFormConfig = require("./billingAddressFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BillingAddress(_ref) {
  var _companyProfile$name, _companyProfile$addre3, _companyProfile$addre4, _companyProfile$addre5, _companyProfile$addre6, _companyProfile$addre7, _companyProfile$addre8, _companyProfile$addre9, _companyProfile$addre10, _companyProfile$addre11, _companyProfile$addre12, _companyProfile$addre13, _companyProfile$addre14;
  var {
    currentBillingAddress,
    continueToNextStep,
    t,
    currentLang,
    setBillingAddress,
    companyProfile
  } = _ref;
  var baseDataTestId = 'Payment';
  var testid = (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAdress-MainContainer');
  var [isLocationRequired, setIsLocationRequired] = (0, _react.useState)(false);
  var countryCode = () => {
    var _companyProfile$addre;
    if (companyProfile !== null && companyProfile !== void 0 && (_companyProfile$addre = companyProfile.address) !== null && _companyProfile$addre !== void 0 && _companyProfile$addre.country) {
      var _companyProfile$addre2;
      return companyProfile === null || companyProfile === void 0 || (_companyProfile$addre2 = companyProfile.address) === null || _companyProfile$addre2 === void 0 ? void 0 : _companyProfile$addre2.country;
    } else if (currentLang === _utils.GLOBALS.language.EN) {
      return _utils.GLOBALS.localeUpper.GB;
    } else {
      return _utils.GLOBALS.localeUpper.DE;
    }
  };
  var [defaultValues, setDefaultValues] = (0, _react.useState)({
    companyName: (_companyProfile$name = companyProfile === null || companyProfile === void 0 ? void 0 : companyProfile.name) !== null && _companyProfile$name !== void 0 ? _companyProfile$name : '',
    addressLine1: (_companyProfile$addre3 = companyProfile === null || companyProfile === void 0 || (_companyProfile$addre4 = companyProfile.address) === null || _companyProfile$addre4 === void 0 ? void 0 : _companyProfile$addre4.addressLine1) !== null && _companyProfile$addre3 !== void 0 ? _companyProfile$addre3 : '',
    addressLine2: (_companyProfile$addre5 = companyProfile === null || companyProfile === void 0 || (_companyProfile$addre6 = companyProfile.address) === null || _companyProfile$addre6 === void 0 ? void 0 : _companyProfile$addre6.addressLine2) !== null && _companyProfile$addre5 !== void 0 ? _companyProfile$addre5 : '',
    addressLine3: (_companyProfile$addre7 = companyProfile === null || companyProfile === void 0 || (_companyProfile$addre8 = companyProfile.address) === null || _companyProfile$addre8 === void 0 ? void 0 : _companyProfile$addre8.addressLine3) !== null && _companyProfile$addre7 !== void 0 ? _companyProfile$addre7 : '',
    addressLine4: (_companyProfile$addre9 = companyProfile === null || companyProfile === void 0 || (_companyProfile$addre10 = companyProfile.address) === null || _companyProfile$addre10 === void 0 ? void 0 : _companyProfile$addre10.addressLine4) !== null && _companyProfile$addre9 !== void 0 ? _companyProfile$addre9 : '',
    postalCode: (_companyProfile$addre11 = companyProfile === null || companyProfile === void 0 || (_companyProfile$addre12 = companyProfile.address) === null || _companyProfile$addre12 === void 0 ? void 0 : _companyProfile$addre12.postalCode) !== null && _companyProfile$addre11 !== void 0 ? _companyProfile$addre11 : '',
    cityName: (_companyProfile$addre13 = companyProfile === null || companyProfile === void 0 || (_companyProfile$addre14 = companyProfile.address) === null || _companyProfile$addre14 === void 0 ? void 0 : _companyProfile$addre14.addressLine4) !== null && _companyProfile$addre13 !== void 0 ? _companyProfile$addre13 : '',
    postcodeAddress: '',
    addressSelection: companyProfile ? _utils.GLOBALS.addressType.BUSINESS : _utils.GLOBALS.addressType.HOME,
    billingAddressSelection: companyProfile ? _utils.GLOBALS.billingAddressType.DIFFERENT : _utils.GLOBALS.billingAddressType.CURRENT,
    countryCode: countryCode()
  });
  var getFormState = (0, _react.useCallback)(state => {
    setDefaultValues(state);
  }, [setDefaultValues]);
  (0, _react.useEffect)(() => {
    if (defaultValues.countryCode === 'DE') {
      setIsLocationRequired(true);
    }
  }, [defaultValues.countryCode]);
  var onSubmitAction = data => {
    var billingAddress;
    if (data.billingAddressSelection === 'DifferentAddress') {
      var _data$addressLine, _data$addressLine2, _data$addressLine3, _data$cityName;
      billingAddress = {
        addressLine1: data.addressLine1,
        addressLine2: (_data$addressLine = data.addressLine2) !== null && _data$addressLine !== void 0 ? _data$addressLine : '',
        addressLine3: (_data$addressLine2 = data.addressLine3) !== null && _data$addressLine2 !== void 0 ? _data$addressLine2 : '',
        addressLine4: (_data$addressLine3 = data.addressLine4) !== null && _data$addressLine3 !== void 0 ? _data$addressLine3 : '',
        cityName: (_data$cityName = data.cityName) !== null && _data$cityName !== void 0 ? _data$cityName : '',
        postalCode: data.postalCode.trim(),
        countryCode: data.countryCode,
        companyName: data.companyName,
        addressType: data.addressSelection
      };
    } else {
      billingAddress = currentBillingAddress;
    }
    continueToNextStep(billingAddress);
  };
  var currentAddressLabel = "".concat(currentBillingAddress === null || currentBillingAddress === void 0 ? void 0 : currentBillingAddress.postalCode, " ").concat(currentBillingAddress === null || currentBillingAddress === void 0 ? void 0 : currentBillingAddress.addressLine1);
  return (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread({
    onChange: setBillingAddress
  }, (0, _billingAddressFormConfig.billingAddressFormConfig)({
    getFormState,
    defaultValues,
    onSubmitAction,
    baseDataTestId,
    testid,
    t,
    currentLang,
    currentAddressLabel,
    isLocationRequired,
    setIsLocationRequired,
    companyProfile
  })));
}